/* eslint-disable no-duplicate-case */
/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
/* eslint-disable no-const-assign */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {useForm, Controller} from 'react-hook-form';
import {observer} from 'mobx-react';
import {useStore} from '../../../../store/store';
import {toast} from 'react-toastify';
import {Button} from '../../../shared/Buttons';
import Stepper from '../../../modules/Stepper';
import PageWrapper from '../../../modules/PageWrapper';
import {SelectorWithSearch, SelectorWithCounties} from '../../../shared/Selectors';
import {formateImage} from '../../../functions/formatImage';

const AddOfferAdvertiser = ({isUpdate = false}) => {
  const {id} = useParams();
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Рекламодатели', path: '/advertisers/list', isActive: true},
        {isSeparator: true},
        {
          title: isUpdate ? `Изменение данных Рекламодателя #${id}` : 'Добавление Рекламодателя',
          path: isUpdate ? `/advertisers/updateAdvertiser/${id}` : '/advertisers/add',
          isActive: true,
        },
      ]}
      title={isUpdate ? `Изменение данных Рекламодателя #${id}` : 'Добавление Рекламодателя'}
    >
      <AddAdvertiser isUpdate={isUpdate} id={id} />
    </PageWrapper>
  );
};

const links = [
  {title: 'Общее', link: 'common'},
  {title: 'Кабинет', link: 'profile'},
  {title: 'Прочее', link: 'other'},
];

const AddAdvertiser = ({isVerify, setPreloader, isUpdate, id}) => {
  const store = useStore();

  const [page, setPage] = useState('common');

  const [data, setData] = useState({});
  const [advertisersList, setAdvertisersList] = useState([]);
  const [brandsList, setBrandsList] = useState([]);
  const [countries, setCountries] = useState([]);

  const [image, setImage] = useState(null);

  useEffect(() => {
    if (isVerify && isUpdate) {
      getData();
    }
    if (isVerify && !isUpdate) {
      setPreloader(false);
    }
  }, [isVerify]);

  const getData = async () => {
    const result = await store.advertisersGetAdvertiser(id);

    setData(result);
    setPreloader(false);
  };

  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <Stepper links={links} page={page} />
          <div className='mx-auto mw-600px w-100 pt-15 pb-10'>
            {page === 'common' && (
              <Common
                advertisersList={advertisersList}
                brandsList={brandsList}
                countries={countries}
                setData={setData}
                data={data}
                setPage={setPage}
                isUpdate={isUpdate}
                setImage={setImage}
                image={image}
              />
            )}
            {page === 'profile' && <Finances setData={setData} data={data} setPage={setPage} />}
            {page === 'other' && (
              <Settings setData={setData} data={data} setPage={setPage} isUpdate={isUpdate} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const Common = ({setData, data, setPage, isUpdate, setImage, image}) => {
  const {
    watch,
    control,
    register,
    setValue,
    formState: {errors},
    handleSubmit,
  } = useForm();

  const [imageError, setImageError] = useState(false);
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    formateImage(data.avatar || '', setImage);
    setAvatar(data.avatar || '');
    setValue('name', data?.name || '');
    setValue('hold', data?.hold || '');
  }, [data]);

  const onSubmit = (params) => {
    if (!avatar) return setImageError(true);

    setData({...data, ...params, avatar});
    setPage('profile');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* <Title title={'Общая информация'} /> */}
      <div className='fv-row mb-10 d-flex flex-column flex-start'>
        <label className='form-label required'>Аватар</label>
        <div className='image-input image-input-outline' data-kt-image-input='true'>
          <div
            className='image-input-wrapper w-125px h-125px'
            style={{
              backgroundImage: image,
            }}
          ></div>

          <label
            className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
            data-kt-image-input-action='change'
            data-bs-toggle='tooltip'
            aria-label='Change avatar'
            data-bs-original-title='Change avatar'
            data-kt-initialized='1'
          >
            <i className='ki-duotone ki-pencil fs-7'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>

            <input
              type='file'
              name='avatar'
              accept='.png, .jpg, .jpeg'
              onChange={(e) => {
                formateImage(e.target.files[0], setImage);
                setAvatar(e.target.files[0]);
                setImage(false);
              }}
            />
            <input type='hidden' name='avatar_remove' />
          </label>
        </div>
        {imageError && <div className='text-danger mt-2'>Поле обязательно для заполнения</div>}
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label'>
          <span className='required'>Название Рекламодателя</span>
        </label>
        <input
          className='form-control form-control-lg form-control-solid'
          {...register('name', {required: true})}
        />
        {errors.name?.type === 'required' && (
          <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
        )}
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label'>
          <span className=''>Холд</span>
        </label>
        <input
          className='form-control form-control-lg form-control-solid'
          {...register('hold', {
            required: false,
          })}
          onInput={(e) => (e.target.value = e.target.value.replace(/\D/g, ''))}
        />
        {errors.hold?.type === 'required' && (
          <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
        )}
      </div>

      <div className='d-flex flex-stack justify-content-end pt-10'>
        <div>
          <button className={`btn fw-bold  btn-primary cursor-pointer `} type='submit'>
            Далее
            <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
          </button>
        </div>
      </div>
    </form>
  );
};
const Finances = ({setData, data, setPage}) => {
  const {
    register,
    watch,
    setValue,
    getValues,
    formState: {errors},
    handleSubmit,
  } = useForm();

  useEffect(() => {
    setValue('login', data?.login || '');
    setValue('password', data?.password || '');
    setValue('link', data?.link || '');
  }, [data]);

  const onSubmit = (params) => {
    setData({
      ...data,
      ...params,
    });
    setPage('other');
  };

  const prevPage = () => {
    const params = getValues();
    setData({
      ...data,
      ...params,
    });
    setPage('common');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* <Title title={'Кабинет'} /> */}
      <div className='fv-row mb-10'>
        <label className='form-label'>
          <span className='required'>Логин</span>
        </label>
        <input
          className='form-control form-control-lg form-control-solid'
          {...register('login', {
            required: true,
          })}
        />
        {errors.login?.type === 'required' && (
          <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
        )}
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label'>
          <span className='required'>Пароль</span>
        </label>
        <input
          className='form-control form-control-lg form-control-solid'
          {...register('password', {
            required: true,
          })}
        />
        {errors.password?.type === 'required' && (
          <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
        )}
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label'>
          <span className=''>Ссылка</span>
        </label>
        <input
          className='form-control form-control-lg form-control-solid'
          {...register('link', {
            required: false,
          })}
        />
        {errors.link?.type === 'required' && (
          <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
        )}
      </div>
      <div className='d-flex flex-stack justify-content-between pt-10'>
        <Button
          action={() => {
            prevPage();
          }}
          color='btn-light-primary'
        >
          <i className='ki-duotone ki-arrow-left fs-3 me-1'>
            <span className='path1'></span>
            <span className='path2'></span>
          </i>
          Назад
        </Button>
        <div>
          <button className={`btn fw-bold  btn-primary cursor-pointer `} type='submit'>
            Далее
            <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
          </button>
        </div>
      </div>
    </form>
  );
};

const Settings = observer(({setData, data, setPage, isUpdate}) => {
  const store = useStore();
  const navigate = useNavigate();
  const {
    register,
    setValue,
    getValues,
    formState: {errors},
    handleSubmit,
  } = useForm({});

  useEffect(() => {
    setValue('note', data?.note || '');
  }, [data]);

  const onSubmit = async (params) => {
    const formData = new FormData();

    for (let [key, value] of Object.entries({...data, ...params})) {
      if (key === 'avatar') {
        if (!!value && typeof value !== 'string') {
          formData.append(`${key}`, value);
          continue;
        }
        continue;
      }
      if (typeof value === 'undefined') value = '';
      formData.append(`${key}`, value);
    }
    for (var value of formData.values()) {
      console.log(value);
    }
    let result;

    if (!isUpdate) {
      result = await store.advertisersAddAdvertiser(formData);
    } else {
      result = await store.advertisersUpdateAdvertiser(formData);
    }

    !!result &&
      toast.success(!isUpdate ? `Успешно сохранено!` : 'Успешно обновлено!', {}) &&
      navigate('/advertisers/list');
    !result &&
      toast.error(`Произошла ошибка сохранения!`, {
        theme: 'colored',
      });
  };

  const prevPage = () => {
    const params = getValues();
    setData({
      ...data,
      ...params,
    });
    setPage('profile');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* <Title title={'Прочее'} /> */}
      <div className='fv-row mb-10'>
        <label className='form-label'>
          <span className=''>Заметка</span>
        </label>
        <textarea
          className='form-control form-control-lg form-control-solid'
          style={{minHeight: '200px'}}
          placeholder=''
          {...register('note', {
            required: false,
          })}
        />
        {errors.link?.type === 'required' && (
          <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
        )}
      </div>
      <div className='d-flex flex-stack justify-content-between pt-10'>
        <Button
          action={() => {
            prevPage();
          }}
          color='btn-light-primary'
        >
          <i className='ki-duotone ki-arrow-left fs-3 me-1'>
            <span className='path1'></span>
            <span className='path2'></span>
          </i>
          Назад
        </Button>
        <div>
          <button className={`btn fw-bold  btn-primary cursor-pointer `} type='submit'>
            Сохранить
          </button>
        </div>
      </div>
    </form>
  );
});

const Title = ({title, subTitle}) => {
  return (
    <div className='pb-3 pb-lg-3'>
      <h2 className='fw-bolder d-flex align-items-center text-gray-900'>{title}</h2>
      <div className='text-gray-500 fw-bold fs-6'>{subTitle}</div>
    </div>
  );
};

export default observer(AddOfferAdvertiser);
