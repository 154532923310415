/* eslint-disable no-sparse-arrays */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, setState, useState, useRef} from 'react';
import css from '../../../../styles/PwaConstructor.module.scss';
import classNames from 'classnames';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import {useThemeMode} from '../../../../_metronic/partials';
import {useNavigate} from 'react-router-dom';
import imagePlug from '../../../../_metronic/assets/media/user.png';
import Stepper from '../Stepper';
import BlockTitles from '../templates/BlockTitles';
import Preloader from '../../../modules/Preloader';
import LandingForm from '../LandingForm';

const ServicesForm = observer(
  ({
    setPreloader,
    validationForm,
    offers = [],
    advertisersOptions = [],
    countriesObj = [],
    landings = [],
    checkedService,
    checkedType,
    postWithToken,
    putWithToken,
    token,
    updateDataForServices,
    pwaToCopy,
    id,
  }) => {
    const store = useStore();
    const theme = useThemeMode();
    const navigate = useNavigate();
    const [themeMode, setThemeMode] = useState('');

    const [prel, setPrel] = useState(false);

    const [step, setStep] = useState(1);

    const [dataOffersFormFt, setDataOffersFormFt] = useState({});
    const [dataOffersFormBc, setDataOffersFormBc] = useState({});

    const [formNameError, setFormNameError] = useState([]);
    const [formOffersError, setForOffersError] = useState(false);
    const [formDomainError, setFormDomainError] = useState([]);

    const [saveData, setSaveData] = useState({});

    useEffect(() => {
      if (!!updateDataForServices) {
        setDataOffersFormBc(updateDataForServices?.offers);
        setDataOffersFormFt(updateDataForServices?.offers);
        setSaveData(updateDataForServices?.data);
      }
    }, [updateDataForServices]);

    const saveOffers = async (obj) => {
      if (!obj.offer_landing_id) {
        return setForOffersError(true);
      } else {
        setDataOffersFormBc(obj);
        setDataOffersFormFt(obj);
        setForOffersError(false);
        setStep(3);
      }
    };

    useEffect(() => {
      if (theme.mode === 'system') {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          setThemeMode('dark');
        } else {
          setThemeMode('light');
        }
      } else {
        setThemeMode(theme.mode);
      }
    }, [theme]);

    const fileToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => resolve(reader.result); // Успішне зчитування
        reader.onerror = (error) => reject(error); // Помилка зчитування

        reader.readAsDataURL(file); // Зчитуємо як Data URL
      });
    };

    const handleIcon = async (event) => {
      const file = event.target.files[0]; // Отримуємо файл

      if (file) {
        const base64 = await fileToBase64(file); // Перетворюємо у Base64

        const result = {
          avatar: base64,
          avatar_name: file.name, // Зберігаємо назву файлу
        };

        setSaveData((prev) => ({...prev, ...result})); // Повертаємо об'єкт
      }
    };

    const saveName = async () => {
      const newData = {
        ...saveData,
      };
      const valArr = ['name', 'avatar'];
      const validation = validationForm(valArr, newData);

      if (validation.length > 0) {
        return setFormNameError(validation);
      }
      setFormNameError([]);
      setStep(2);
    };

    const save = async () => {
      const newData = {
        ...saveData,
      };
      const valArr = ['domain'];
      const validation = validationForm(valArr, newData);
      if (validation.length > 0) {
        return setFormDomainError(validation);
      }
      setFormDomainError([]);

      const obj = {
        ...saveData,
        ...dataOffersFormBc,
        type: checkedType.value,
        service_id: checkedService,
      };

      if (!saveData.avatar_name) delete obj.avatar;

      if (!!pwaToCopy && !id) {
        pwaCopy();
      } else {
        if (id) {
          await putWithToken(`pwa/${id}/updateAppExternal`, token, obj);
        } else {
          await postWithToken('pwa/createAppExternal', token, obj);
        }
        navigate(`/app/${checkedType.value}`);
      }
    };

    const pwaCopy = async () => {
      const obj = {
        ...dataOffersFormBc,
        name: saveData?.name,
        domain: saveData?.domain,
        copy_notifications: pwaToCopy.addPushes,
        copy_split: pwaToCopy.addOffers,
      };
      console.log({
        ...dataOffersFormBc,
        name: saveData?.name,
        domain: saveData?.domain,
        copy_notifications: pwaToCopy.addPushes,
        copy_split: pwaToCopy.addOffers,
      });
      // const postSubDomain = await postWithToken(`domains/${dataDomainForm?.domain_id}/sub`, token, {
      //   name: dataDomainForm?.subdomain,
      // });

      // obj.subdomain_id = postSubDomain.data.id;
      // const createPwa = await postWithToken(`pwa/${pwaToCopy.id}/copy`, token, obj);

      // const putDomain = await putWithToken(`pwa/${createPwa?.data?.id}/updatePwaDomain`, token, {
      //   subdomain_id: postSubDomain.data.id,
      //   domain_id: dataDomainForm?.domain_id,
      // });

      // navigate(`/app/constructor/${createPwa?.data?.id}?step=4`);
    };

    // useEffect(() => {
    //   console.log(dataOffersFormBc);
    // }, [dataOffersFormBc]);

    return (
      <div
        className={css.container}
        style={{
          '--text-1': themeMode === 'dark' ? 'rgba(245, 245, 245, 1)' : 'rgba(31, 33, 41, 1)',
          '--text-2': themeMode === 'dark' ? 'rgba(100, 102, 116, 1)' : 'rgba(152, 161, 183, 1)',
          '--bg': themeMode === 'dark' ? 'rgba(21, 23, 28, 1)' : 'rgba(255, 255, 255, 1)',
          '--activeColor': themeMode === 'dark' ? 'rgba(27, 132, 255, 1)' : 'rgba(27, 132, 255, 1)',
          '--inputBg': themeMode === 'dark' ? 'rgba(27, 28, 33, 1)' : 'rgba(249, 249, 249, 1)',
          '--colorLine': themeMode === 'dark' ? 'rgba(53, 57, 66, 1)' : 'rgba(225, 227, 234, 1)',
          '--buttonGrayBg': themeMode === 'dark' ? 'rgba(27, 28, 33, 1)' : 'rgba(241, 241, 244, 1)',
          '--slideSelectorBg':
            themeMode === 'dark' ? 'rgba(27, 28, 33, 1)' : 'rgba(241, 243, 244, 1)',
          '--slideSelectorItemBg':
            themeMode === 'dark' ? 'rgba(42, 46, 54, 1)' : 'rgba(255, 255, 255, 1)',
          '--buttonCommentBg':
            themeMode === 'dark' ? 'rgba(24, 35, 49, 1)' : 'rgba(232, 242, 254, 1)',
        }}
      >
        <div className={css.constructorContainer}>
          <Stepper themeMode={themeMode} step={step} links={['Настройки', 'Трафик', 'Домен']} />
          <div className={css.table}>
            <section className={css.form} style={{position: 'relative'}}>
              {prel && <Preloader style={{border: 'none'}} />}
              {step === 1 && (
                <>
                  <div className={css.nameForm}>
                    <BlockTitles
                      title={'Настройки'}
                      description={'Настройте основные системные настройки для своего Приложения'}
                    />
                    <label htmlFor='' className={css.inputField} style={{marginTop: '30px'}}>
                      <span>Название</span>
                      <input
                        type='text'
                        value={saveData.name || ''}
                        onChange={(e) =>
                          setSaveData({
                            ...saveData,
                            name: e.target.value,
                          })
                        }
                        style={
                          formNameError.includes('name')
                            ? {border: '2px solid red', outline: 'none'}
                            : {}
                        }
                      />
                    </label>
                  </div>
                  <div className={css.blockHeader}>
                    <span style={{display: 'inline-block', marginBottom: '8px'}}>Аватар</span>
                    <div
                      className={css.icon}
                      style={{
                        backgroundImage: !saveData.avatar
                          ? `url(${imagePlug})`
                          : `url(${saveData.avatar})`,
                        border: formNameError.includes('name') ? '2px solid red' : '',
                      }}
                    >
                      <label htmlFor='appIcon'>
                        <div className={classNames(css.delete)}></div>

                        <input
                          type='file'
                          accept='.png, .jpg, .jpeg'
                          name=''
                          id='appIcon'
                          onChange={handleIcon}
                        />
                      </label>
                    </div>
                  </div>
                  <div className={css.next}>
                    <button className={css.blueButton} onClick={saveName}>
                      Продолжить
                    </button>
                  </div>
                </>
              )}
              {step === 2 && (
                <>
                  <LandingForm
                    advertisersOptions={advertisersOptions}
                    offers={offers}
                    countries={countriesObj}
                    landings={landings}
                    dataFt={dataOffersFormFt}
                    setDataFt={setDataOffersFormFt}
                    dataBc={dataOffersFormBc}
                    setDataBc={setDataOffersFormBc}
                    setStep={setStep}
                    saveOffers={saveOffers}
                    errors={formOffersError}
                  />
                </>
              )}
              {step === 3 && (
                <>
                  <div className={css.nameForm}>
                    <BlockTitles title={'Домен'} />
                    <label htmlFor='' className={css.inputField}>
                      <span>Укажите домен</span>
                      <input
                        style={
                          formDomainError.includes('domain')
                            ? {border: '2px solid #F8285A', outline: 'none'}
                            : {}
                        }
                        type='text'
                        value={saveData.domain}
                        onChange={(e) => {
                          setSaveData((prev) => ({...prev, domain: e.target.value}));
                        }}
                      />
                    </label>
                  </div>
                  <div className={css.next}>
                    <button
                      className={css.grayButton}
                      onClick={() => setStep(2)}
                      style={{minWidth: '130px'}}
                    >
                      Назад
                    </button>

                    <button className={css.blueButton} style={{minWidth: '130px'}} onClick={save}>
                      Сохранить
                    </button>
                  </div>
                </>
              )}
            </section>
          </div>
        </div>
      </div>
    );
  }
);

export default ServicesForm;
