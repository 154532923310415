/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef, useState} from 'react';
import imagePlug from '../../_metronic/assets/media/user.png';
import {useThemeMode} from '../../_metronic/partials';
import css from '../../styles/FacebookStatisticsTable.module.scss';
import clsx from 'clsx';
import {formatNumber, formatNumber2, formatNumberToCash} from '../functions/formatNumber';
import {customizationColumns} from './customizationTable';
import {Link, useNavigate} from 'react-router-dom';

export const Table = ({
  globalSort,
  columns,
  rows,
  open,
  onSort,
  openRowIdLoading,
  tableLoading,
  isEmptyData,
  fullMode,
  getData,
  editEntity = (id) => {},
  customizationColumns_mode = null,
  timeConfig = null,
  edit = null,
}) => {
  const navigate = useNavigate();
  const theme = useThemeMode();
  const [themeMode, setThemeMode] = useState('');
  const sentinelRef = useRef(null);

  const [totalRows, setTotalRows] = useState([]);
  const [filteredColumns, setFilteredColumns] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);
  const [objectsSelectedRows, setObjectSelectedRows] = useState([]);

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setThemeMode('dark');
      } else {
        setThemeMode('light');
      }
    } else {
      setThemeMode(theme.mode);
    }
  }, [theme]);

  useEffect(() => {
    if (selectedRows.length === 0) return setObjectSelectedRows([]);
    const resRows = [];
    selectedRows.forEach((id) => {
      rows.forEach((obj, index) => {
        if (id === `${obj.id}${obj.name}${index}`) resRows.push(obj);
      });
    });
    setObjectSelectedRows(calculateTotalRow(resRows));
  }, [selectedRows]);

  function formatWithCommasAndNoRounding(value) {
    const arr = String(value).split('.');

    if (arr[1] >= 2) {
      return `${arr[0]}.${arr[1][0]}${arr[1][1]}`;
    } else if (arr[1] === 1) {
      return `${arr[0]}.${arr[1][0]}0`;
    } else {
      return `${arr[0]}`;
    }
  }

  const renderHeaders = (columns) => {
    const rows = [];

    const processColumns = (columns, depth = 0, group) => {
      if (!rows[depth]) rows[depth] = [];
      columns.forEach((it) => {
        const hasSubColumns = it?.subColumns && it?.subColumns?.length > 0;
        const item =
          customizationColumns_mode === null
            ? customizationColumns(it)
            : customizationColumns_mode(it);
        const colSpan = hasSubColumns ? item?.subColumns.reduce((sum, subColumn) => sum + 1, 0) : 0;

        rows[depth].push(
          <th
            key={`${item.column?.name || item.name}-${depth}`}
            className={clsx(
              css.table__th,
              !hasSubColumns && !!item?.column?.name && css.table__th_head,
              hasSubColumns && css.headGroup,
              item?.startIndex && css.headGroup__start,
              item?.endIndex && css.headGroup__end
            )}
            colSpan={colSpan}
            rowSpan={hasSubColumns ? 1 : 2}
            onClick={() => {
              if (!hasSubColumns) {
                onSort({
                  group,
                  sort: item.column?.sort === 'asc' || item?.sort === 'asc' ? 'desc' : 'asc',
                  name: item.column?.name || item?.name,
                });
              }
            }}
          >
            <div
              className={clsx(
                css.nameContainer,
                (!!item.column?.sort || !!item?.sort) && css.nameSortable
              )}
              // data-title={item?.clue}
              title={item?.clue}
              style={{display: 'flex', alignItems: 'center'}}
            >
              {(item?.column?.marker || item?.marker) && (
                <span
                  className={css.marker}
                  style={{backgroundColor: `${item?.column?.marker || item?.marker}`}}
                ></span>
              )}
              <span className='text-gray-500'>{item?.column?.label || item?.label}</span>
              {!hasSubColumns && (
                <span
                  className={clsx(
                    css.sort,
                    (item.column?.sort === 'asc' || item?.sort === 'asc') &&
                      (item?.column?.name === globalSort.name || item.name === globalSort.name) &&
                      css._active
                  )}
                ></span>
              )}
            </div>
          </th>
        );

        if (hasSubColumns) {
          processColumns(item?.subColumns, depth + 1, item?.column?.name);
        }
      });
    };

    processColumns(columns);
    return rows.map((row, index) => <tr key={index}>{row}</tr>);
  };

  const renderRows = (rows, columns) => {
    return rows.map((row, rowIndex) => {
      const idWithName = `${row.id}${row.name}${rowIndex}`;

      return (
        <tr
          key={rowIndex}
          className={clsx(css.table__tr, selectedRows.includes(idWithName) && 'bg-light-warning')}
          onClick={() => {
            const findSelectedRowIndex = selectedRows.findIndex(
              (item, index) => item === idWithName
            );
            if (findSelectedRowIndex === -1) {
              setSelectedRows((prev) => [...prev, idWithName]);
            } else {
              const newSelectedRows = [...selectedRows];
              newSelectedRows.splice(findSelectedRowIndex, 1);
              setSelectedRows(newSelectedRows);
            }
          }}
        >
          {columns.map((col, colIndex) => {
            if (col.subColumns) {
              return col.subColumns.map((subCol, i) => {
                const newSubColumn =
                  customizationColumns_mode === null
                    ? customizationColumns(subCol)
                    : customizationColumns_mode(subCol);
                return (
                  <td
                    key={`${newSubColumn.name}-${rowIndex}`}
                    className={clsx(
                      css.table__td,
                      css.isSub,
                      i === col.subColumns.length - 1 && css.cellEnd,
                      newSubColumn.isExpandable && css.table__td_primary,
                      newSubColumn.fontWeight === 'bold' && 'fw-bold'
                    )}
                    style={{color: newSubColumn?.color, backgroundColor: newSubColumn?.subColor}}
                  >
                    <div
                      className={css.nameContainer}
                      style={
                        newSubColumn.colorForLess || newSubColumn.colorForMore
                          ? +row[col.column.name]?.[newSubColumn.name] < 0
                            ? {color: `${newSubColumn.colorForLess}`}
                            : +row[col.column.name]?.[newSubColumn.name] > 0
                            ? {color: `${newSubColumn.colorForMore}`}
                            : {}
                          : {}
                      }
                    >
                      {newSubColumn.type === 'conversion' && (
                        <span>
                          {row[col.column.name]?.[newSubColumn.name] === undefined ||
                          +row[col.column.name]?.[newSubColumn.name] === 0
                            ? '-'
                            : `${formatNumber2(
                                formatWithCommasAndNoRounding(
                                  row[col.column.name]?.[newSubColumn.name]
                                )
                              )}%`}
                        </span>
                      )}
                      {newSubColumn.type === 'cash' && (
                        <span>
                          {row[col.column.name]?.[newSubColumn.name] === undefined ||
                          +row[col.column.name]?.[newSubColumn.name] === 0
                            ? '-'
                            : `${formatNumberToCash(
                                formatWithCommasAndNoRounding(
                                  row[col.column.name]?.[newSubColumn.name]
                                )
                              )}`}
                        </span>
                      )}
                      {newSubColumn.type === 'count' && (
                        <span>
                          {row[col.column.name]?.[newSubColumn.name] === undefined ||
                          +row[col.column.name]?.[newSubColumn.name] === 0
                            ? '-'
                            : `${formatNumber(
                                formatWithCommasAndNoRounding(
                                  row[col.column.name]?.[newSubColumn.name]
                                )
                              )}`}
                        </span>
                      )}
                      {newSubColumn.isExpandable && <div className={css.table__openRow}>+</div>}
                    </div>
                  </td>
                );
              });
            }
            const newColumn =
              customizationColumns_mode === null
                ? customizationColumns(col)
                : customizationColumns_mode(col);

            const getLinkUrl = () => {
              if (!newColumn.column.rout) {
                return '';
              }

              let baseUrl = `${newColumn.column.rout}${row.id}`;

              if (!newColumn.column.isTimeConfig) {
                return baseUrl;
              }

              let queryString = '';

              if (timeConfig?.time_config && timeConfig.time_config !== 'undefined') {
                queryString += `time_config=${timeConfig.time_config}&`;
              }

              if (timeConfig?.time_start && timeConfig.time_start !== 'undefined') {
                queryString += `time_start=${timeConfig.time_start}&`;
              }

              if (timeConfig?.time_end && timeConfig.time_end !== 'undefined') {
                queryString += `time_end=${timeConfig.time_end}&`;
              }

              // Видаляємо зайвий `&` у кінці, якщо він є
              queryString = queryString.replace(/&$/, '');

              return queryString ? `${baseUrl}?${queryString}` : baseUrl;
            };

            return (
              <td
                key={`${col.column.name}-${rowIndex}`}
                className={clsx(
                  css.table__td,
                  css.table__name,
                  col?.column?.isExpandable && css.table__td_primary,
                  newColumn?.column?.fontWeight === 'bold' && 'fw-bold',
                  selectedRows.includes(idWithName) && 'bg-light-warning'
                )}
              >
                <div className={clsx(css.name, css.nameContainer, css.nameForRows)}>
                  {newColumn?.column?.type === 'conversion' && (
                    <span>
                      {+row[newColumn?.column?.name] === 0
                        ? '-'
                        : `${formatNumber2(row[newColumn?.column?.name])}%`}
                    </span>
                  )}
                  {newColumn?.column?.type === 'cash' && (
                    <span>
                      {+row[newColumn.column.name] === 0
                        ? '-'
                        : `${formatNumberToCash(row[newColumn.column.name])}`}
                    </span>
                  )}
                  {newColumn?.column?.type === 'count' && (
                    <span>
                      {+row[newColumn.column.name] === 0
                        ? '-'
                        : `${formatNumber(row[newColumn.column.name])}`}
                    </span>
                  )}
                  {newColumn?.column?.type === 'link' && (
                    <Link
                      to={getLinkUrl()}
                      target='_blank'
                      style={{display: 'flex', gap: '10px', alignItems: 'center'}}
                    >
                      {row[newColumn?.column?.name] && (
                        <span
                          style={{
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%',
                            backgroundColor: !!row.is_active ? '#34C77E' : '#FF3B30',
                          }}
                        ></span>
                      )}
                      {row[newColumn?.column?.name] && row?.avatar && (
                        <img
                          src={row?.avatar}
                          style={{
                            width: '20px',
                            height: '20px',
                            objectFit: 'contain',
                            borderRadius: '2px',
                          }}
                          onError={(e) => (e.target.src = imagePlug)}
                        ></img>
                      )}
                      <span
                        className='fw-bold text-gray-800'
                        style={
                          !row[newColumn?.column?.name] || !newColumn?.column?.rout
                            ? {}
                            : {
                                textDecorationLine: 'underline',
                                textDecorationStyle: 'dotted',
                                textUnderlineOffset: '4px',
                                cursor: 'pointer',
                              }
                        }
                      >
                        {row.id === row.name && typeof row[newColumn.column.name] === 'string'
                          ? row.name
                          : row[newColumn.column.name]}
                      </span>
                      {edit === newColumn?.column?.edit && !row.parentId && (
                        <>
                          {!!newColumn?.column?.isEdit && (
                            <div
                              style={{padding: 4}}
                              role='button'
                              onClick={(e) => {
                                editEntity(row.id);
                                e.preventDefault();
                              }}
                            >
                              <i className='ki-duotone ki-pencil fs-7'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                              </i>
                            </div>
                          )}
                          {!!newColumn?.column?.isEditLink && (
                            <div
                              style={{padding: 4, cursor: 'pointer'}}
                              role='button'
                              onClick={(e) => {
                                navigate(`${newColumn?.column?.isEditLink}/${row.id}`);
                                e.preventDefault();
                              }}
                            >
                              <i className='ki-duotone ki-pencil fs-7'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                              </i>
                            </div>
                          )}
                        </>
                      )}
                    </Link>
                  )}

                  {newColumn.column.isExpandable && row[newColumn.column.name] && (
                    <>
                      {openRowIdLoading === row.id ? (
                        <div
                          className={clsx('spinner-border text-primary', css.table__openRow)}
                          style={{width: 17, height: 17}}
                          role='status'
                        >
                          <span className='sr-only'>Loading...</span>
                        </div>
                      ) : (
                        <div
                          className={clsx(css.table__openRow, 'd-flex align-items-center fs-1')}
                          onClick={(e) => {
                            e.stopPropagation();
                            open(
                              row.id,
                              row.name,
                              colIndex,
                              rowIndex,
                              newColumn.column.name,
                              row.globalID,
                              columns[colIndex + 1]
                            );
                          }}
                        >
                          {row.isOpen ? '-' : '+'}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </td>
            );
          })}
        </tr>
      );
    });
  };

  const renderTotalRow = (rows, columns, type = '') => {
    const calcHeight = document.getElementById('total')?.getBoundingClientRect()?.height;

    return (
      <tr
        className={clsx(css.table__tr, css.table__tr_total)}
        style={type === 'calc' ? {bottom: `${calcHeight}px`} : {}}
        id={'total'}
      >
        {type !== 'calc' && <td className={css.table__td}>Всего</td>}
        {type === 'calc' && (
          <td className={css.table__td}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '10px',
                padding: '0px 5px 0px 0px',
              }}
              onClick={() => setSelectedRows([])}
            >
              <span>Выбрано</span> <div className={css.selectClose}></div>
            </div>
          </td>
        )}

        {columns.map((col, colIndex) => {
          if (colIndex === 0) return null;

          if (col.column.isExpandable) {
            return (
              <td key={`total-${col.column.name}`} className={css.table__td}>
                <span></span>
              </td>
            );
          }

          if (col.subColumns) {
            return col.subColumns.map((subCol) => {
              const newSubColumn =
                customizationColumns_mode === null
                  ? customizationColumns(subCol)
                  : customizationColumns_mode(subCol);
              const totalVal = rows[col.column.name][subCol.name] || 0;
              return (
                <td
                  key={`total-${subCol.name}`}
                  className={css.table__td}
                  style={{color: newSubColumn?.color, backgroundColor: newSubColumn?.subColor}}
                >
                  <div
                    className={css.nameContainer}
                    style={
                      newSubColumn.colorForLess || newSubColumn.colorForMore
                        ? +totalVal < 0
                          ? {color: `${newSubColumn.colorForLess}`}
                          : +totalVal > 0
                          ? {color: `${newSubColumn.colorForMore}`}
                          : {}
                        : {}
                    }
                  >
                    {newSubColumn.type === 'conversion' && (
                      <span>
                        {totalVal === 0
                          ? '-'
                          : `${formatNumber2(formatWithCommasAndNoRounding(totalVal))}%`}
                      </span>
                    )}
                    {newSubColumn.type === 'cash' && (
                      <span>
                        {totalVal === 0
                          ? '-'
                          : formatNumberToCash(formatWithCommasAndNoRounding(totalVal))}
                      </span>
                    )}
                    {newSubColumn.type === 'count' && (
                      <span>
                        {totalVal === 0
                          ? '-'
                          : formatNumber(formatWithCommasAndNoRounding(totalVal))}
                      </span>
                    )}
                  </div>
                </td>
              );
            });
          } else {
            const totalVal = rows[col.column.name];
            const newColumn =
              customizationColumns_mode === null
                ? customizationColumns(col)
                : customizationColumns_mode(col);
            return (
              <td key={`total-${col.column.name}`} className={css.table__td}>
                <div
                  className={css.nameContainer}
                  style={
                    newColumn.colorForLess || newColumn.colorForMore
                      ? +totalVal < 0
                        ? {color: `${newColumn.colorForLess}`}
                        : +totalVal > 0
                        ? {color: `${newColumn.colorForMore}`}
                        : {}
                      : {}
                  }
                >
                  {newColumn.column.type === 'conversion' && (
                    <span>{totalVal === 0 ? '-' : `${formatNumber2(totalVal)}%`}</span>
                  )}
                  {newColumn.column.type === 'cash' && (
                    <span>{totalVal === 0 ? '-' : formatNumberToCash(totalVal)}</span>
                  )}
                  {newColumn.column.type === 'count' && (
                    <span>{totalVal === 0 ? '-' : formatNumber(totalVal)}</span>
                  )}
                </div>
              </td>
            );
          }
        })}
      </tr>
    );
  };
  useEffect(() => {
    const handleChangeTableWith = () => {
      const header = document.getElementById('kt_app_header');
      const filters1 = document.getElementById('facebook_table_filters');

      const table = document.getElementById('facebook_statistics_table');

      table.style.height = `${
        window.innerHeight -
        header.getBoundingClientRect().height -
        (filters1.getBoundingClientRect().height + 16 + (!fullMode && 120))
      }px`;
    };
    handleChangeTableWith();
    document.addEventListener('resize', handleChangeTableWith);

    return () => {
      document.removeEventListener('resize', handleChangeTableWith);
    };
  }, [fullMode]);

  const calculateTotalRow = (rows) => {
    const count_clicks = rows.reduce((acc, item) => acc + item?.clicks?.count_clicks, 0);
    const count_leads = rows.reduce((acc, item) => acc + item?.leads?.count_leads, 0);
    const count_registrations = rows.reduce(
      (acc, item) => acc + item?.registrations?.count_registrations,
      0
    );
    const count_qualifications = rows.reduce(
      (acc, item) => acc + item?.qualifications?.count_qualifications,
      0
    );
    const count_appeals = rows.reduce((acc, item) => acc + item?.chats?.count_appeals, 0);
    const count_first_deposits = rows.reduce(
      (acc, item) => acc + item?.first_deposits?.count_first_deposits,
      0
    );
    const cost = rows.reduce((acc, item) => acc + item?.finance?.cost, 0);
    const revenue = rows.reduce((acc, item) => acc + item?.finance?.revenue, 0);
    const profit = rows.reduce((acc, item) => acc + item?.finance?.profit, 0);

    const safeDivide = (numerator, denominator) => {
      return denominator === 0 ? 0 : (numerator / denominator) * 100;
    };

    return {
      clicks: {
        count_clicks,
        click2lead: safeDivide(count_leads, count_clicks),
        click2appeal: safeDivide(count_appeals, count_clicks),
        click2reg: safeDivide(count_registrations, count_clicks),
        click2fd: safeDivide(count_first_deposits, count_clicks),
        click2cpa: safeDivide(count_qualifications, count_clicks),
        cpc: safeDivide(cost, count_clicks) / 100,
      },
      leads: {
        count_leads,
        lead2appeal: safeDivide(count_appeals, count_leads),
        lead2reg: safeDivide(count_registrations, count_leads),
        lead2fd: safeDivide(count_first_deposits, count_leads),
        lead2cpa: safeDivide(count_qualifications, count_leads),
        cpl: safeDivide(cost, count_leads) / 100,
      },
      chats: {
        count_appeals,
        chat2reg: safeDivide(count_registrations, count_appeals),
        chat2fd: safeDivide(count_first_deposits, count_appeals),
        chat2cpa: safeDivide(count_qualifications, count_appeals),
        cpm: safeDivide(cost, count_appeals) / 100,
      },
      registrations: {
        count_registrations,
        reg2fd: safeDivide(count_first_deposits, count_registrations),
        reg2cpa: safeDivide(count_qualifications, count_registrations),
        cpr: safeDivide(cost, count_registrations) / 100,
      },
      first_deposits: {
        count_first_deposits,
        fd2cpa: safeDivide(count_qualifications, count_first_deposits),
        cpf: safeDivide(cost, count_first_deposits) / 100,
      },
      qualifications: {
        count_qualifications,
        cpa: safeDivide(cost, count_qualifications) / 100,
      },
      finance: {
        revenue,
        cost,
        profit: revenue - cost,
        romi: safeDivide(revenue - cost, cost),
      },
    };
  };

  useEffect(() => {
    setTotalRows(calculateTotalRow(rows));
    setFilteredColumns(
      columns.filter(
        (item) => item?.column?.name !== 'avatar' && item?.column?.name !== 'is_active'
      )
    );
  }, [rows, columns]);

  return (
    <div
      id='facebook_statistics_table'
      className={clsx(css.table__container, fullMode && css.table__container_full)}
      style={{
        '--bg': themeMode === 'dark' ? '#15171C' : '#FCFCFC',
        '--borderTable': themeMode === 'dark' ? 'rgba(30, 31, 36, 1)' : '#E4E4E5',
        '--bgRowTable': themeMode === 'dark' ? 'rgba(27, 29, 35, 1)' : 'rgba(245, 246, 247, 1)',
        '--bgRowTableActive':
          themeMode === 'dark' ? 'rgba(38, 41, 48, 1)' : 'rgba(229, 229, 229, 1)',
        '--bgRowTableHover':
          themeMode === 'dark' ? 'rgba(38, 41, 48, 1)' : 'rgba(229, 229, 229, 1)',
        '--closeColor': themeMode === 'dark' ? 'hsl(0, 0%, 80%)' : 'hsl(0, 0%, 20%)',
      }}
    >
      {tableLoading ? (
        <div className='progress' style={{height: 2}}>
          <div
            className={clsx(
              'progress-bar progress-bar-striped progress-bar-animated h-1',
              css.progressBar
            )}
            role='progressbar'
            style={{width: '100%'}}
          ></div>
        </div>
      ) : (
        <>
          <table className={css.table}>
            <thead className={css.table__head}>{renderHeaders(filteredColumns)}</thead>
            <tbody>
              {isEmptyData && (
                <tr style={{width: '100%', height: 40}}>
                  <td>
                    <h6 className={clsx(css.emptyData, 'text-gray-600')}>Нет данных</h6>
                  </td>
                </tr>
              )}
              {!isEmptyData && renderRows(rows, filteredColumns)}
              {rows.length > 1 &&
                Object.keys(objectsSelectedRows).length > 0 &&
                renderTotalRow(objectsSelectedRows, filteredColumns, 'calc')}
              {rows.length > 1 &&
                Object.keys(totalRows).length > 0 &&
                renderTotalRow(totalRows, filteredColumns)}
            </tbody>
          </table>
          <div ref={sentinelRef} style={{height: '2px'}} />
        </>
      )}
    </div>
  );
};
