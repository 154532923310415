/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import PageWrapper from '../../../modules/PageWrapper';
import {useParams} from 'react-router-dom';
import {CreateAppModal} from '../../../../_metronic/partials';
import Card from '../../../../../src/app/shared/Card';
import 'moment/locale/ru';
import moment from 'moment';
import {InputCopy} from '../../../shared/Inputs';
import Preloader from '../../../modules/Preloader';
import PaginationBe from '../../../shared/PaginationBe';
import UpdatePixel from './UpdatePixel';
import {SelectorWithSearch} from '../../../shared/Selectors';
import {useThemeMode} from '../../../../_metronic/partials';

const Pixel = observer(({isVerify, timeConfig, setPreloader, id}) => {
  const store = useStore();
  const [data, setData] = useState({});
  const [events, setEvents] = useState([]);
  const [table, setTable] = useState({});
  const [limit, setLimit] = useState(8);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [prel, setPrel] = useState(false);

  useEffect(() => {
    isVerify && getData();
  }, [isVerify]);

  useEffect(() => {
    isVerify && timeConfig.time_config && getTable(page);
  }, [isVerify, timeConfig]);

  useEffect(() => {
    const containersXL = document.querySelectorAll('.container-xxl');
    containersXL.forEach((el) => (el.style.maxWidth = '100%'));
    return () => {
      containersXL.forEach((el) => (el.style.maxWidth = ''));
    };
  }, []);

  const getData = async () => {
    const getPixel = await store.getPixel(id);
    const getFacebookEvents = await store.getFacebookEvents();

    setEvents(getFacebookEvents);
    setData(getPixel);
    setPreloader(false);
  };

  const getTable = async () => {
    setPrel(true);
    const getPixelLogs = await store.getPixelLogsPortion({
      timeConfig,
      id,
      page: 1,
      perPage: limit,
    });

    setTotal(getPixelLogs.total);
    setTable(getPixelLogs);
    setPrel(false);
  };

  return (
    <div className='row g-5 g-xl-10 mt-0'>
      <div className='col-xl-3 mt-0 mb-xl-0 mb-10'>
        <div className={`card`}>
          <div className='card-header py-7'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold text-gray-800'>
                {data?.name} <span className='text-muted'>#{data.id}</span>
              </span>
              <span className='pt-2 fw-semibold fs-6'>{data.pixel_id}</span>
            </h3>
            {store.roles.includes(38) || store.roles.includes(6) || store.roles.includes(7) ? (
              <></>
            ) : (
              <div className='card-toolbar'>
                <ButtonUpdate updateData={data} getPixels={getData} />
              </div>
            )}
          </div>
          <div className='card-body p-9'>
            <div className='row mb-7'>
              <label className='col-6 fw-semibold text-muted'>Конфиг</label>

              <div className='col-6 d-flex align-items-center justify-content-end'>
                <span className='fw-bold fs-6 text-gray-800 me-2'>{data?.config}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-6 fw-semibold text-muted'>Статус</label>

              <div className='col-6 d-flex align-items-center justify-content-end'>
                <span
                  className={`badge ${!data.status ? 'badge-light-danger' : 'badge-light-success'}`}
                >
                  {!!data?.status ? 'Активный' : 'Не активный'}
                </span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-6 fw-semibold text-muted'>Создан</label>

              <div className='col-6 d-flex align-items-center justify-content-end'>
                <span className='fw-bold fs-6 text-gray-800 me-2'>
                  {moment(data?.created_at * 1000).format('ll')}
                </span>
              </div>
            </div>
            {store.roles.includes(6) || store.roles.includes(7) ? (
              <></>
            ) : (
              <div className='row mb-7 d-flex align-items-center'>
                <label className='col-5 fw-semibold text-muted'>Маркер доступа</label>

                <div className='col-7 d-flex align-items-center '>
                  <InputCopy text={data?.pixel_token} />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={`card mt-7`}>
          <div className='card-header py-7'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold text-gray-800'>Facebook Events</span>
            </h3>
          </div>
          <div className='card-body p-9'>
            <div className='row mb-7'>
              <EventItem
                title={'Подписка на канал'}
                stat={events?.find((item) => item.id === data?.subscribe_event_id)?.code}
                icon={
                  <i className='ki-duotone ki-fasten  fs-1'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                }
              />
              <EventItem
                title={'Запуск бота'}
                stat={events?.find((item) => item.id === data?.bot_event_id)?.code}
                icon={
                  <i className='ki-duotone ki-to-right  fs-1'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                }
              />
              <EventItem
                title={'Обращения'}
                subTitle={'Кол-во обращений'}
                stat={events?.find((item) => item.id === data?.appeal_event_id)?.code}
                icon={
                  <i className='ki-duotone ki-message-add fs-1'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                }
              />
              <EventItem
                title={'Регистрации'}
                subTitle={'Кол-во регистраций'}
                stat={events?.find((item) => item.id === data?.registration_event_id)?.code}
                icon={
                  <i className='ki-duotone ki-user-edit fs-1'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                }
              />
              <EventItem
                title='Квалификации'
                subTitle='Кол-во квалификаций'
                stat={events?.find((item) => item.id === data?.cpa_event_id)?.code}
                icon={
                  <i className='ki-duotone ki-user-tick fs-1'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                }
              />
              <EventItem
                title='Первый депозит'
                stat={events?.find((item) => item.id === data?.fd_event_id)?.code}
                icon={
                  <i className='ki-duotone ki-save-deposit  fs-1'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                }
              />
              <EventItem
                title='Второй депозит'
                stat={events?.find((item) => item.id === data?.rd_event_id)?.code}
                icon={
                  <i className='ki-duotone ki-wallet fs-1'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                }
              />
              <EventItem
                title='Любой депозит'
                stat={events?.find((item) => item.id === data?.deposit_event_id)?.code}
                icon={
                  <i className='ki-duotone ki-dollar fs-1'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className='col-xl-9 mt-0 h-xl-100'>
        <Card className='card'>
          <Table
            id={id}
            table={table}
            limit={limit}
            page={page}
            setPage={setPage}
            getTable={getTable}
            prel={prel}
            setPrel={setPrel}
            events={events}
            timeConfig={timeConfig}
            total={total}
            setTotal={setTotal}
          />
        </Card>
      </div>
    </div>
  );
});

const Table = observer(
  ({
    id,
    table,
    limit,
    page,
    setPage,
    getTable,
    prel,
    setPrel,
    events,
    timeConfig,
    total,
    setTotal,
  }) => {
    const store = useStore();
    const theme = useThemeMode();
    const [mode, setMode] = useState('');
    const [input, setInput] = useState('');
    const [timeoutId, setTimeoutId] = useState(null);

    const [eventsOptions, setEventsOptions] = useState([]);
    const [event, setEvent] = useState({});
    const [statusesOptions, setStatusesOptions] = useState([
      {label: 200, value: 200},
      {label: 400, value: 400},
    ]);
    const [status, setStatus] = useState({});

    const [list, setList] = useState([]);

    useEffect(() => {
      setList(table?.rows);
    }, [table]);

    useEffect(() => {
      setEventsOptions(
        events.map((item) => {
          return {label: item.code, value: item.code};
        })
      );
    }, [events]);

    const filterSearch = async (text) => {
      setPrel(true);
      setPage(1);
      const getPixelLogs = await store.getPixelLogsPortion({
        timeConfig,
        id,
        page: 1,
        limit,
        q: text,
        status: !!status?.value ? status?.value : '',
        event_name: !!event?.value ? event?.value : '',
      });

      setTotal(getPixelLogs.total);
      setList(getPixelLogs.rows);
      setPrel(false);
    };

    const filterStatus = async (filter) => {
      setPrel(true);
      setPage(1);
      const getPixelLogs = await store.getPixelLogsPortion({
        timeConfig,
        id,
        page: 1,
        limit,
        status: !!filter?.value ? filter?.value : '',
        event_name: !!event?.value ? event?.value : '',
        q: input,
      });

      setTotal(getPixelLogs.total);
      setList(getPixelLogs.rows);
      setPrel(false);
    };

    const filterEvent = async (filter) => {
      setPrel(true);
      setPage(1);
      const getPixelLogs = await store.getPixelLogsPortion({
        timeConfig,
        id,
        page: 1,
        limit,
        status: !!status?.value ? status?.value : '',
        event_name: !!filter?.value ? filter?.value : '',
        q: input,
      });

      setTotal(getPixelLogs.total);
      setList(getPixelLogs.rows);
      setPrel(false);
    };

    const filterPage = async (currentPage) => {
      setPrel(true);
      const getPixelLogs = await store.getPixelLogsPortion({
        timeConfig,
        id,
        page: currentPage,
        limit,
        status: !!status?.value ? status?.value : '',
        event_name: !!event?.value ? event?.value : '',
        q: input,
      });

      setTotal(getPixelLogs.total);
      setList(getPixelLogs.rows);
      setPrel(false);
    };

    const handleSearch = (event) => {
      const value = event.target.value;
      setInput(value);

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      setPrel(true);
      const newTimeoutId = setTimeout(() => {
        filterSearch(value);
      }, 1000);

      setTimeoutId(newTimeoutId);
    };

    useEffect(() => {
      if (theme.mode === 'system') {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          setMode('dark');
        } else {
          setMode('light');
        }
      } else {
        setMode(theme.mode);
      }
    }, [theme]);

    return (
      <div className='card-body py-4'>
        <div className='card-header border-0 p-0' style={{display: 'flex'}}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              gap: '8px',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: '8px',
              }}
            >
              <SelectorWithSearch
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '42px',
                    boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                    backgroundColor: mode === 'dark' ? '#1B1C22 !important' : '#F9F9F9 !important',
                  }),
                  singleValue: (baseStyles, state) => ({
                    ...baseStyles,
                    color: mode === 'dark' ? '#9A9CAE !important' : '#4B5675 !important',
                  }),
                }}
                placeholder='Событие...'
                options={eventsOptions}
                actions={(e) => {
                  setEvent(e);
                  filterEvent(e);
                }}
                value={event}
                clearable={true}
              />
              <SelectorWithSearch
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '42px',
                    boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                    backgroundColor: mode === 'dark' ? '#1B1C22 !important' : '#F9F9F9 !important',
                  }),
                  singleValue: (baseStyles, state) => ({
                    ...baseStyles,
                    color: mode === 'dark' ? '#9A9CAE !important' : '#4B5675 !important',
                  }),
                }}
                placeholder='Статус...'
                options={statusesOptions}
                actions={(e) => {
                  setStatus(e);
                  filterStatus(e);
                }}
                value={status}
                clearable={true}
              />
            </div>
            <div className='d-flex align-items-center position-relative'>
              <i className='ki-duotone ki-magnifier fs-3 position-absolute ms-5'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              <input
                style={{height: '43px'}}
                type='text'
                className='form-control form-control-solid w-100 mw-250px ps-13'
                placeholder='Поиск...'
                value={input}
                onChange={handleSearch}
              />
            </div>
          </div>
        </div>
        <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
          <div className='table-responsive'>
            <table className='table align-middle table-row-dashed fs-6 gy-5'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th className='min-w-100px'>#</th>
                  {/* <th className='min-w-125px'>LEAD PIXEL ID</th> */}
                  <th className='w-280px'>Событие</th>
                  <th className='min-w-250px'>РК</th>
                  <th className='w-125px text-center'>Статус</th>
                  <th className='min-w-125px'>Отправлен</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {list?.map((el, i) => {
                  return <Item key={el.id} el={el} />;
                })}
              </tbody>
            </table>
            {prel && <Preloader style={{border: '0'}} />}
          </div>
        </div>
        <PaginationBe
          arrLength={total}
          limit={limit}
          page={page}
          setPage={setPage}
          action={(currentPage) => filterPage(currentPage)}
        />
      </div>
    );
  }
);

const Item = ({el}) => {
  useEffect(() => {
    moment.locale('ru');
  }, []);

  const formatTimeAgo = (milliseconds) => {
    if (new Date().getTime() - milliseconds * 1000 < 3600000) {
      return moment(milliseconds * 1000)
        .startOf('minute')
        .fromNow();
    }

    return moment(milliseconds * 1000)
      .startOf('hour')
      .fromNow();
  };

  const formateInDots = (str) => {
    const arr = str.split('');
    if (arr.length < 25) {
      return str;
    }
    arr.splice(25);
    arr.push('...');
    return arr.join('');
  };

  return (
    <tr>
      <td>{el?.id}</td>
      <td>{el?.event_name}</td>
      <td>{formateInDots(el?.campaign_name)}</td>
      <td className='text-center'>
        <span
          className={`badge ${el?.status !== 200 ? 'badge-light-danger' : 'badge-light-success'}`}
        >
          {el?.status}
        </span>
      </td>
      <td>{formatTimeAgo(el?.created_at)}</td>
    </tr>
  );
};

const EventItem = ({stat, title, subTitle, icon}) => {
  return (
    <div className='d-flex align-items-center mb-5'>
      <div className='symbol symbol-45px w-40px me-5'>
        <span className='symbol-label bg-lighten'>{icon}</span>
      </div>

      <div className='d-flex align-items-center flex-wrap w-100'>
        <div className='mb-1 pe-3 flex-grow-1'>
          <div className='fs-5 text-gray-800 fw-bold mb-2'>{title}</div>
          <div className='text-gray-400 fw-semibold'>
            <span className='badge badge-light-primary fs-5'>{stat}</span>
          </div>
        </div>

        <div className='d-flex align-items-center'>
          <div className='fw-bold fs-5 text-gray-800 pe-1'></div>
        </div>
      </div>
    </div>
  );
};

const ButtonUpdate = ({getPixels, updateData}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);

  return (
    <>
      <span
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        onClick={() => {
          setShowCreateAppModal(true);
          setSave(false);
        }}
      >
        <i className='ki-duotone ki-pencil fs-2'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>
      </span>

      <CreateAppModal
        customWidth='mw-550px'
        show={showCreateAppModal}
        handleClose={() => {
          if (save) {
            setShowCreateAppModal(false);
          } else {
            setWarning(true);
          }
        }}
        title={'Редактирование пикселя'}
      >
        <UpdatePixel
          setWarning={setWarning}
          warning={warning}
          setSave={setSave}
          save={save}
          show={setShowCreateAppModal}
          getPixels={getPixels}
          isUpdate={true}
          updateData={updateData}
        />
      </CreateAppModal>
    </>
  );
};

const PixelWrapper = () => {
  const {id} = useParams();
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'Трекер'},
        {isSeparator: true},
        {title: 'Facebook'},
        {isSeparator: true},
        {title: 'Пиксели', path: '/tracker/facebook/pixels'},
        {isSeparator: true},
        {title: `Пиксель #${id}`, path: '/tracker/facebook/pixels/1', isActive: true},
      ]}
      title={`Пиксель #${id}`}
      firstTimeConfig='current_month'
    >
      <Pixel id={id} />
    </PageWrapper>
  );
};

export default PixelWrapper;
