/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, forwardRef} from 'react';
import css from '../../../../../styles/PwaConstructor.module.scss';
import pickerCss from '../../../../../styles/CustomPicker.module.scss';
import classNames from 'classnames';
import imagePlug from '../../../../../_metronic/assets/media/user.png';
import DatePicker from 'react-datepicker';
import MaskedTextInput from 'react-text-mask';
import BlockLine from '../BlockLine';
import BlockTitles from '../BlockTitles';
import {base_config_site, googleplay_site} from '../objectsTemplates';

const FormSite = ({
  themeMode,
  config,
  setConfig,
  currentLang,
  post,
  id,
  token,
  createPwa,
  setId,
}) => {
  const [timeVersion, setTimeVersion] = useState(0);

  // useEffect(() => {
  //   setTimeVersion(new Date().getTime());
  // }, [config])

  const deleteImage = async (id, name, dir) => {
    fetch('https://api.raulnk.com/uploads', {
      method: 'DELETE',
      body: JSON.stringify({
        id: +id,
        lang: currentLang,
        name,
        dir,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleIcon = async (e) => {
    let newId = null;
    if (!id) {
      newId = await createPwa();
      setId(newId);
    }
    const renamedFile = new File([e.target.files[0]], 'icon.png', {type: e.target.files[0].type});

    if (!!config[currentLang]?.appIcon) {
      await deleteImage(id || newId, config[currentLang]?.appIcon, 'images/icons');
    }

    const formData = new FormData();
    formData.append('file', renamedFile); // Додаємо файл у форму
    formData.append('id', id || newId);
    formData.append('lang', currentLang);
    formData.append('name', 'icon.png');
    formData.append('dir', 'images/icons');

    fetch('https://api.raulnk.com/uploads', {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
        setConfig({
          ...config,
          [currentLang]: {
            ...config[currentLang],
            appIcon: 'icon.png',
          },
        });
        setTimeVersion(new Date().getTime());
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleSlides = async (e) => {
    let newId = null;
    if (!id) {
      newId = await createPwa();
      setId(newId);
    }

    const inputFiles = Array.from(e.target.files);
    const slidesLength = config[currentLang]?.slides?.length;

    const imagesNames = inputFiles.map((item, index) => {
      return `${index + slidesLength}_slide.png`;
    });

    for (const [index, file] of inputFiles.entries()) {
      const newName = `${index + slidesLength}_slide.png`;

      const newFile = new File([file], newName, {
        type: file.type,
      });

      // Відправляємо файл на сервер
      const formData = new FormData();
      formData.append('file', newFile);
      formData.append('id', id || newId);
      formData.append('lang', currentLang);
      formData.append('name', newName);
      formData.append('dir', `images/slides`);

      try {
        const response = await fetch('https://api.raulnk.com/uploads', {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          console.log(`Файл ${newName} успішно відправлено`);

          if (index === inputFiles.length - 1) {
            const newSlides = [...config[currentLang].slides, ...imagesNames];
            setConfig({...config, [currentLang]: {...config[currentLang], slides: newSlides}});
          }
          setTimeVersion(new Date().getTime());
        } else {
          console.error(`Помилка при відправці файлу ${newName}`);
        }
      } catch (error) {
        console.error(`Помилка мережі для файлу ${newName}:`, error);
      }
    }
  };

  const deleteSlide = async (index, item) => {
    await deleteImage(id, item, 'images/slides');

    setConfig((prevConfig) => {
      const newSlides = [...prevConfig[currentLang].slides];
      newSlides.splice(index, 1);
      return {
        ...prevConfig,
        [currentLang]: {
          ...prevConfig[currentLang],
          slides: newSlides,
        },
      };
    });
  };

  const handleRange = (e) => {
    const {id, value} = e.target;
    const newValue = +value;
    const currentValue = config[currentLang].ratings[id];

    let sumWithoutCurrent =
      Object.values(config[currentLang].ratings).reduce((accum, current) => accum + current, 0) -
      currentValue;

    if (sumWithoutCurrent + newValue <= 100) {
      setConfig((prevConfig) => ({
        ...prevConfig,
        [currentLang]: {
          ...prevConfig[currentLang],
          ratings: {...prevConfig[currentLang].ratings, [id]: newValue},
        },
      }));
    } else {
      const maxAllowedValue = 100 - sumWithoutCurrent;
      setConfig((prevConfig) => ({
        ...prevConfig,
        [currentLang]: {
          ...prevConfig[currentLang],
          ratings: {...prevConfig[currentLang].ratings, [id]: maxAllowedValue},
        },
      }));
    }
  };

  useEffect(() => {
    if (!config[currentLang] || Object.keys(config[currentLang])?.length === 0) return;

    const range = 2;
    const ratingsObj = config[currentLang]?.ratings;
    if (!ratingsObj || !Object.keys(ratingsObj).length) return;

    const updateRatingsCount = (value) =>
      setConfig((prevConfig) => ({
        ...prevConfig,
        [currentLang]: {...prevConfig[currentLang], ratingsCount: value.toFixed(1)},
      }));

    if (
      ratingsObj.a1 >= range &&
      ratingsObj.a2 < range &&
      ratingsObj.a3 < range &&
      ratingsObj.a4 < range &&
      ratingsObj.a5 < range
    ) {
      updateRatingsCount(5);
    } else if (
      ratingsObj.a1 < range &&
      ratingsObj.a2 >= range &&
      ratingsObj.a3 < range &&
      ratingsObj.a4 < range &&
      ratingsObj.a5 < range
    ) {
      updateRatingsCount(4);
    } else if (
      ratingsObj.a1 < range &&
      ratingsObj.a2 < range &&
      ratingsObj.a3 >= range &&
      ratingsObj.a4 < range &&
      ratingsObj.a5 < range
    ) {
      updateRatingsCount(3);
    } else if (
      ratingsObj.a1 < range &&
      ratingsObj.a2 < range &&
      ratingsObj.a3 < range &&
      ratingsObj.a4 >= range &&
      ratingsObj.a5 < range
    ) {
      updateRatingsCount(2);
    } else if (
      ratingsObj.a1 < range &&
      ratingsObj.a2 < range &&
      ratingsObj.a3 < range &&
      ratingsObj.a4 < range &&
      ratingsObj.a5 >= range
    ) {
      updateRatingsCount(1);
    } else if (
      ratingsObj.a1 < range &&
      ratingsObj.a2 < range &&
      ratingsObj.a3 < range &&
      ratingsObj.a4 < range &&
      ratingsObj.a5 < range
    ) {
      updateRatingsCount(0);
    } else {
      const averageRating =
        (ratingsObj.a5 * 1 +
          ratingsObj.a4 * 2 +
          ratingsObj.a3 * 3 +
          ratingsObj.a2 * 4 +
          ratingsObj.a1 * 5) /
        100;
      updateRatingsCount(averageRating);
    }
  }, [config[currentLang]?.ratings]);

  const addComment = () => {
    const oneDay = 86400000;
    setConfig((prevConfig) => ({
      ...prevConfig,
      [currentLang]: {
        ...prevConfig[currentLang],
        comments: [
          ...prevConfig[currentLang]?.comments,
          {
            avatar: '',
            rating: 0,
            date: new Date()?.setHours(0, 0, 0, 0),
            author: '',
            comment: '',
            response: '',
            likes: 0,
            hide: false,
          },
        ],
      },
    }));
  };

  const handleAvatar = async (e, index, name) => {
    let newId = null;
    if (!id) {
      newId = await createPwa();
      setId(newId);
    }

    if (!!config[currentLang]?.comments[index]?.avatar) {
      await deleteImage(id || newId, config[currentLang]?.comments[index].avatar, 'images/users');
    }

    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Додаємо файл у форму
    formData.append('id', id || newId);
    formData.append('lang', currentLang);
    formData.append('name', !name ? `${index}-avatar.png` : name);
    formData.append('dir', 'images/users');

    fetch('https://api.raulnk.com/uploads', {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setConfig((prevConfig) => {
          const updatedComments = [...prevConfig[currentLang].comments];
          updatedComments[index] = {
            ...updatedComments[index],
            avatar: !name ? `${index}-avatar.png` : name,
          };
          return {
            ...prevConfig,
            [currentLang]: {
              ...prevConfig[currentLang],
              comments: updatedComments,
            },
          };
        });
        setTimeVersion(new Date().getTime());
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const changeCommentRating = (index, count) => {
    setConfig((prevConfig) => {
      const updatedComments = [...prevConfig[currentLang].comments];
      updatedComments[index] = {...updatedComments[index], rating: count};
      return {
        ...prevConfig,
        [currentLang]: {
          ...prevConfig[currentLang],
          comments: updatedComments,
        },
      };
    });
  };

  const changeComments = (e, index) => {
    const {name, value} = e.target;
    setConfig((prevConfig) => {
      const updatedComments = [...prevConfig[currentLang].comments];
      updatedComments[index] = {...updatedComments[index], [name]: value};
      return {
        ...prevConfig,
        [currentLang]: {
          ...prevConfig[currentLang],
          comments: updatedComments,
        },
      };
    });
  };

  const deleteComment = async (index, name) => {
    await deleteImage(id, `${name}`, 'images/users');

    setConfig((prevConfig) => {
      const updatedComments = [...prevConfig[currentLang].comments];
      updatedComments.splice(index, 1);
      return {
        ...prevConfig,
        [currentLang]: {
          ...prevConfig[currentLang],
          comments: updatedComments,
        },
      };
    });
  };

  const hideComment = (index) => {
    setConfig((prevConfig) => {
      const updatedComments = [...prevConfig[currentLang].comments];
      updatedComments[index] = {...updatedComments[index], hide: !updatedComments[index].hide};
      return {
        ...prevConfig,
        [currentLang]: {
          ...prevConfig[currentLang],
          comments: updatedComments,
        },
      };
    });
  };

  const CustomInput = forwardRef(({value, onClick, onChange}, ref) => (
    <MaskedTextInput
      className='form-control form-control-solid mb-3 mb-lg-0 mw-100'
      onClick={onClick}
      onChange={onChange}
      value={value}
      ref={ref}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
    />
  ));

  const buttonFields = ['install', 'installing', 'download', 'cancel', 'open', 'delete', 'trailer'];

  const titleFields = [
    {key: 'aboutThisApp', placeholder: 'About this app'},
    {key: 'dataSafety', placeholder: 'Data safety'},
    {key: 'ratings', placeholder: 'Rating and reviews'},
  ];

  const inputFields = [
    {placeholders: ['Contains ad', 'In-app purchases'], keys: ['contains_1', 'contains_2']},
    {placeholders: ['Reviews'], keys: ['reviews']},
    {placeholders: ['Downloads', '1M+'], keys: ['downloads', 'countDownloads']},
    {placeholders: ['Device'], keys: ['phone']},
    {placeholders: ['Data safety title 1'], keys: ['dataSafety_title_1']},
    {placeholders: ['Data safety text 1'], keys: ['dataSafety_text_1']},
    {placeholders: ['Data safety title 2'], keys: ['dataSafety_title_2']},
    {placeholders: ['Data safety text 2'], keys: ['dataSafety_text_2']},
    {placeholders: ['Data safety title 3'], keys: ['dataSafety_title_3']},
    {placeholders: ['Data safety title 4'], keys: ['dataSafety_title_4']},
    {placeholders: ['See details', 'See all reviews'], keys: ['seeDetails', 'seeAllReviews']},
    {
      placeholders: ['Did you find this helpful?'],
      keys: ['helpFull'],
    },
    {placeholders: ['Yes', 'No'], keys: ['yes', 'no']},
    {placeholders: ['Share', 'Add to wishlist'], keys: ['share', 'addList']},
  ];

  const handleInputChange = (key, value) => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      [currentLang]: {
        ...prevConfig[currentLang],
        inscriptions: {
          ...prevConfig[currentLang].inscriptions,
          [key]: key === 'countDownloads' ? value.replace(/\D/g, '') : value,
        },
      },
    }));
  };

  return (
    <>
      <BlockTitles
        title={'Приложение'}
        description={'На данном этапе настраивается внешний вид Приложения и текстовое содержание.'}
      />
      <BlockLine />
      <div className={css.blockConfig}>
        <span>
          Использовать базовую конфигурацию (если вы выберете этот вариант, контент на этой вкладке
          будет перезаписан):
        </span>
        <input
          checked={config[currentLang]?.base || false}
          type='checkbox'
          onChange={(e) => {
            if (!!e.target.checked) {
              setConfig({
                ...config,
                [currentLang]: {
                  ...config[currentLang],
                  titles: base_config_site.titles,
                  texts: base_config_site.texts,
                  buttons: base_config_site.buttons,
                  inscriptions: base_config_site.inscriptions,
                  base: e.target.checked,
                },
              });
            } else {
              setConfig({
                ...config,
                [currentLang]: {
                  ...config[currentLang],
                  titles: googleplay_site.titles,
                  texts: googleplay_site.texts,
                  buttons: googleplay_site.buttons,
                  inscriptions: googleplay_site.inscriptions,
                  base: e.target.checked,
                },
              });
            }
          }}
        />
      </div>
      <BlockLine />
      <BlockTitles
        title={'Оформление страницы'}
        // description={
        //   'Вы можете создать дизайн с нуля или повторить внешний вид уже существующего приложения'
        // }
      />
      <BlockLine />
      <div className={css.blockConfig}>
        <label htmlFor='' style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
          <span>RTL:</span>
          <input
            type='checkbox'
            name=''
            id=''
            checked={config[currentLang].rtl}
            onChange={(e) =>
              setConfig({...config, [currentLang]: {...config[currentLang], rtl: e.target.checked}})
            }
          />
        </label>
      </div>
      <BlockLine />
      <div className={css.blockHeader}>
        <div
          className={css.icon}
          style={{
            backgroundImage: !config[currentLang]?.appIcon
              ? `url(${imagePlug})`
              : `url(https://api.raulnk.com/${id}/images/icons/${currentLang}/${config[currentLang]?.appIcon}?v=${timeVersion})`,
          }}
        >
          <label htmlFor='appIcon'>
            <div className={classNames(css.delete)}></div>

            <input
              type='file'
              accept='.png, .jpg, .jpeg'
              name=''
              id='appIcon'
              onChange={handleIcon}
            />
          </label>
        </div>
        <div className={css.inputs}>
          <label htmlFor='' className={css.inputField}>
            <span>Название приложения</span>
            <input
              type='text'
              value={config[currentLang]?.appName || ''}
              onChange={(e) =>
                setConfig({
                  ...config,
                  [currentLang]: {...config[currentLang], appName: e.target.value},
                })
              }
            />
          </label>
          <label htmlFor='' className={css.inputField}>
            <span>Разработчик</span>
            <input
              type='text'
              value={config[currentLang]?.developer || ''}
              onChange={(e) =>
                setConfig({
                  ...config,
                  [currentLang]: {...config[currentLang], developer: e.target.value},
                })
              }
            />
          </label>
          <label htmlFor='' className={css.inputField}>
            <span>Размер</span>
            <input
              type='text'
              value={config[currentLang]?.appSize || ''}
              onChange={(e) =>
                setConfig({
                  ...config,
                  [currentLang]: {...config[currentLang], appSize: e.target.value},
                })
              }
            />
          </label>
        </div>
      </div>
      <BlockLine />
      <BlockTitles title={'Видео'} description={'Вставте ссылку на видео'} />
      <div className={css.blockHeader}>
        <label htmlFor='' className={css.inputField}>
          <input
            type='text'
            value={config[currentLang]?.video || ''}
            onChange={(e) =>
              setConfig({
                ...config,
                [currentLang]: {...config[currentLang], video: e.target.value},
              })
            }
          />
        </label>
      </div>
      <BlockLine />
      <BlockTitles
        title={'Слайды'}
        description={'Добавте слайды в том порядке в котором хотите их увидеть в приложении'}
      />
      <div className={css.blockSlides}>
        <div className={css.slides}>
          {config[currentLang]?.slides?.map((item, index) => {
            return (
              <div
                className={css.slide}
                key={`${item}-${index}`}
                style={{
                  backgroundImage: `url(https://api.raulnk.com/${id}/images/slides/${currentLang}/${item}?v=${timeVersion})`,
                }}
              >
                <div
                  className={css.delete}
                  onClick={() => {
                    deleteSlide(index, item);
                  }}
                />
              </div>
            );
          })}
        </div>
        <label className={css.add} htmlFor='slides'>
          <input type='file' name='' id='slides' multiple onChange={handleSlides} />
        </label>
      </div>
      <BlockLine />
      <BlockTitles title={'Описание приложения'} description={'Напишите описание для приложения'} />
      <div className={css.blockDescription}>
        <label htmlFor='' className={css.inputField}>
          <textarea
            name='response'
            placeholder='Описание приложения'
            value={config[currentLang]?.description || ''}
            onChange={(e) =>
              setConfig({
                ...config,
                [currentLang]: {...config[currentLang], description: e.target.value},
              })
            }
          ></textarea>
        </label>
      </div>
      <BlockLine />
      <BlockTitles
        title={'Оценки и отзывы'}
        description={'В этом блоке вы можете настроить оценки пользователей и их коментарии'}
      />
      <div className={css.blockReviews}>
        <label htmlFor='' className={css.inputField}>
          <span>Количество отзывов</span>
          <input
            type='text'
            value={config[currentLang]?.reviews || ''}
            onChange={(e) =>
              setConfig({
                ...config,
                [currentLang]: {...config[currentLang], reviews: e.target.value.replace(/\D/g, '')},
              })
            }
          />
        </label>
        <label htmlFor='' className={css.inputField}>
          <span>Рейтинг</span>
          <input type='text' value={config[currentLang]?.ratingsCount || ''} disabled />
        </label>
        <div
          className={css.rangers}
          style={{
            '--rangeColor': themeMode === 'dark' ? 'rgba(27, 28, 33, 1)' : 'rgba(234, 234, 234, 1)',
          }}
        >
          <label htmlFor='' className={css.range}>
            <span>5</span>
            <input
              type='range'
              name=''
              id='a1'
              min='1'
              max='100'
              value={config[currentLang]?.ratings?.a1 || 0}
              onChange={handleRange}
            />
          </label>
          <label htmlFor='' className={css.range}>
            <span>4</span>
            <input
              type='range'
              id='a2'
              min='1'
              max='100'
              value={config[currentLang]?.ratings?.a2 || 0}
              onChange={handleRange}
            />
          </label>
          <label htmlFor='' className={css.range}>
            <span>3</span>
            <input
              type='range'
              name=''
              id='a3'
              min='1'
              max='100'
              value={config[currentLang]?.ratings?.a3 || 0}
              onChange={handleRange}
            />
          </label>
          <label htmlFor='' className={css.range}>
            <span>2</span>
            <input
              type='range'
              name=''
              id='a4'
              min='1'
              max='100'
              value={config[currentLang]?.ratings?.a4 || 0}
              onChange={handleRange}
            />
          </label>
          <label htmlFor='' className={css.range}>
            <span>1</span>
            <input
              type='range'
              name=''
              id='a5'
              min='1'
              max='100'
              value={config[currentLang]?.ratings?.a5 || 0}
              onChange={handleRange}
            />
          </label>
        </div>
      </div>
      <BlockLine />
      <BlockTitles title={'Комментарии'} description={'Добавте коментарии пользователей'} />
      <div className={css.blockComments}>
        {config[currentLang]?.comments?.map((item, index) => {
          return (
            <div
              className={classNames(css.comment, item.hide && css._hide)}
              key={`comment-${index}`}
            >
              <div className={css.header}>
                <label
                  className={css.avatar}
                  htmlFor={`commentAvatar-${index}`}
                  style={
                    item.avatar
                      ? {
                          backgroundImage: `url(https://api.raulnk.com/${id}/images/users/${currentLang}/${item.avatar}?v=${timeVersion})`,
                        }
                      : {}
                  }
                >
                  <input
                    type='file'
                    accept='.png, .jpg, .jpeg'
                    name=''
                    id={`commentAvatar-${index}`}
                    onChange={(e) => handleAvatar(e, index, item.avatar)}
                  />
                </label>
                <div className={css.rating}>
                  <span className={css.t}>Рейтинг</span>
                  <div className={css.stars}>
                    <div
                      className={classNames(
                        css.star,
                        themeMode === 'dark' && css._dark,
                        item.rating >= 1 && css._active
                      )}
                      onClick={() => changeCommentRating(index, 1)}
                    ></div>
                    <div
                      className={classNames(
                        css.star,
                        themeMode === 'dark' && css._dark,
                        item.rating >= 2 && css._active
                      )}
                      onClick={() => changeCommentRating(index, 2)}
                    ></div>
                    <div
                      className={classNames(
                        css.star,
                        themeMode === 'dark' && css._dark,
                        item.rating >= 3 && css._active
                      )}
                      onClick={() => changeCommentRating(index, 3)}
                    ></div>
                    <div
                      className={classNames(
                        css.star,
                        themeMode === 'dark' && css._dark,
                        item.rating >= 4 && css._active
                      )}
                      onClick={() => changeCommentRating(index, 4)}
                    ></div>
                    <div
                      className={classNames(
                        css.star,
                        themeMode === 'dark' && css._dark,
                        item.rating >= 5 && css._active
                      )}
                      onClick={() => changeCommentRating(index, 5)}
                    ></div>
                    <span className={css.counter}>{item.rating}</span>
                  </div>
                </div>
                <div className={css.actions}>
                  <div
                    className={classNames(
                      css.hide,
                      themeMode === 'dark' && css._dark,
                      item.hide && css._hide
                    )}
                    onClick={() => hideComment(index)}
                  ></div>
                  <div className={css.delete} onClick={(e) => deleteComment(index, item.avatar)} />
                </div>
              </div>
              <div className={css.body}>
                {/* <label htmlFor='' className={css.inputField}>
                  <span>Дата</span>
                  <DatePicker
                    selected={!item.date ? new Date().getTime() : new Date(item.date).getTime()}
                    calendarClassName={classNames(
                      pickerCss.container,
                      pickerCss[`_${themeMode}`],
                      themeMode === 'dark' ? pickerCss.dark : pickerCss.light,
                      'month-year-select'
                    )}
                    showYearDropdown
                    showMonthDropdown
                    dateFormat='dd/MM/yyyy'
                    onChange={(e) => changeCommentDate(e, index)}
                    customInput={<CustomInput />}
                  />
                </label> */}
                <label htmlFor='' className={css.inputField}>
                  <span>Автор комментария</span>
                  <input
                    type='text'
                    name='author'
                    value={item.author || ''}
                    onChange={(e) => changeComments(e, index)}
                  />
                </label>
                {/* <label htmlFor='' className={css.inputField}>
                  <span>Кол-во лайков</span>
                  <input
                    type='text'
                    name='likes'
                    value={item.likes || ''}
                    onChange={(e) => changeComments(e, index)}
                  />
                </label> */}
                <label htmlFor='' className={css.inputField}>
                  <textarea
                    name='comment'
                    id=''
                    placeholder='Текст коментария'
                    value={item.comment || ''}
                    onChange={(e) => changeComments(e, index)}
                  ></textarea>
                </label>
                <label htmlFor='' className={css.inputField}>
                  <textarea
                    name='response'
                    placeholder='Ответ разработчика'
                    value={item.response || ''}
                    onChange={(e) => changeComments(e, index)}
                  ></textarea>
                </label>
              </div>
            </div>
          );
        })}
      </div>
      <button className={css.addComment} onClick={addComment}>
        Добавить комментарий +
      </button>
      <BlockLine />
      <BlockTitles
        title={'Локализация приложения'}
        description={
          'В этом блоке вы можете заполнить перевод оставшихся элементов интерфейса на странице, а также их метрики.'
        }
      />
      <div className={css.blockLocalization}>
        <span className={css.t}>Кнопки</span>
        {buttonFields.map((field) => (
          <label htmlFor='' className={css.inputField} key={field}>
            <input
              type='text'
              placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
              value={config[currentLang]?.buttons?.[field] || ''}
              onChange={(e) =>
                setConfig({
                  ...config,
                  [currentLang]: {
                    ...config[currentLang],
                    buttons: {...config[currentLang].buttons, [field]: e.target.value},
                  },
                })
              }
            />
          </label>
        ))}
        <span className={css.t}>Заголовки секций</span>
        {titleFields.map(({key, placeholder}) => (
          <label htmlFor='' className={css.inputField} key={key}>
            <input
              type='text'
              placeholder={placeholder}
              value={config[currentLang]?.titles?.[key] || ''}
              onChange={(e) =>
                setConfig({
                  ...config,
                  [currentLang]: {
                    ...config[currentLang],
                    titles: {...config[currentLang].titles, [key]: e.target.value},
                  },
                })
              }
            />
          </label>
        ))}
        <span className={css.t}>Описание секций</span>
        <label htmlFor='' className={css.inputField}>
          <textarea
            name='response'
            placeholder='Data Safety'
            value={config[currentLang]?.texts?.dataSafety || ''}
            onChange={(e) =>
              setConfig({
                ...config,
                [currentLang]: {
                  ...config[currentLang],
                  texts: {...config[currentLang].texts, dataSafety: e.target.value},
                },
              })
            }
          ></textarea>
        </label>
        <span className={css.t}>Оставшиеся поля</span>
        {inputFields.map((field, index) => (
          <label key={index} htmlFor='' className={css.inputField}>
            <div className={css.doubleFields}>
              {field.placeholders.map((placeholder, i) => (
                <input
                  key={i}
                  type='text'
                  placeholder={placeholder}
                  value={config[currentLang]?.inscriptions?.[field.keys[i]] || ''}
                  onChange={(e) => handleInputChange(field.keys[i], e.target.value)}
                />
              ))}
            </div>
          </label>
        ))}
      </div>
    </>
  );
};

export default FormSite;
