/* eslint-disable no-unused-vars */
import React, {useState} from 'react';
import {useStore} from '../../../../../../store/store';
import {observer} from 'mobx-react';
import numeral from 'numeral';
import moment from 'moment';
import Navigation from '../../../../../modules/NavigationBloggers';
import Card from '../../../../../shared/Card';
import {formatNumber2, formatNumberToCash} from '../../../../../functions/formatNumber';
import {Link} from 'react-router-dom';

const Header = ({
  setPage,
  page,
  links = [{link: 'employees', title: 'Сотрудники'}],
  data,
  traffic,
  actionComponents,
}) => {
  const store = useStore();
  return (
    <>
      <Card className='card mb-8'>
        <div className='card-body pt-6 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-1'>
            <div className='flex-grow-1'>
              <div className='d-md-flex justify-content-start align-items-center flex-wrap mb-1'>
                <div className='d-flex flex-column me-4'>
                  <div className='d-flex align-items-center mb-1'>
                    <span className='text-gray-800 text-hover-primary fs-2 fw-bold me-3'>
                      {data?.name}
                    </span>
                    {!store.roles.includes(19) && (
                      <span className={`badge badge-light-${data?.indicator?.indicator} me-auto`}>
                        {formatNumber2(data?.romi)}%
                      </span>
                    )}
                  </div>

                  <div className='d-flex flex-wrap fw-semibold mb-1 fs-5 text-gray-400'>
                    <span className='d-flex align-items-center text-gray-500 text-hover-primary me-2 mb-1'>
                      #{data?.id}
                    </span>
                    <Link to={`/tracker/facebook/companies/${traffic?.id}`}>
                      <span className='d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-1 cursor-pointer'>
                        <i className='ki-duotone ki-abstract-26 fs-2 me-2'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>{' '}
                        {traffic?.name}
                      </span>
                    </Link>
                  </div>
                </div>
                <div className='d-flex flex-wrap justify-content-start'>
                  <div className='d-flex flex-wrap'>
                    {!store.roles.includes(19) && (
                      <Stats
                        title={'Прибыль'}
                        count={`${formatNumberToCash(data?.profit)}`}
                        arrow={data?.profit >= 0 ? true : false}
                      />
                    )}
                    {!store.roles.includes(19) && (
                      <Stats
                        title={'Доход'}
                        count={`${formatNumberToCash(data?.revenue)}`}
                        arrow={true}
                      />
                    )}
                    <Stats
                      title={'Расходы'}
                      count={`${formatNumberToCash(data?.cost)}`}
                      arrow={false}
                    />
                  </div>
                </div>
                <div className='d-flex justify-content-end mb-1 flex-grow-1'>
                  {actionComponents.map((el, i) => {
                    return <div key={i}>{el}</div>;
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className='separator'></div>
          <Navigation page={page} setPage={setPage} links={links} />
        </div>
      </Card>
    </>
  );
};

const Stats = ({title, count, arrow}) => {
  return (
    <div className='border border-gray-300 border-dashed rounded min-w-90px py-2 px-3 me-6 mb-1'>
      <div className='d-flex align-items-center'>
        {!!arrow && (
          <i className='ki-duotone ki-arrow-up fs-3 text-success me-2'>
            <span className='path1'></span>
            <span className='path2'></span>
          </i>
        )}
        {!arrow && (
          <i className='ki-duotone ki-arrow-down fs-3 text-danger me-2'>
            <span className='path1'></span>
            <span className='path2'></span>
          </i>
        )}
        <div className='fs-6 fw-bold'>{count}</div>
      </div>

      <div className='fw-semibold fs-7 text-gray-400'>{title}</div>
    </div>
  );
};

export default observer(Header);
