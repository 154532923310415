/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {useStore} from '../../../../../store/store';
import {observer} from 'mobx-react';
import {useForm, Controller} from 'react-hook-form';
import {toast} from 'react-toastify';
import {IMaskInput} from 'react-imask';
import {CreateAppModal} from '../../../../../_metronic/partials';
import {Button} from '../../../../shared/Buttons';
import Warning from '../../../../modules/Warning';
import {Selector, SelectorIsMulti} from '../../../../shared/Selectors';

const AddSource = ({mainData, traffic_id, getInfluence, romiList, timeConfig}) => {
  const store = useStore();
  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
    setValue,
    getValues,
    reset,
    watch,
  } = useForm();

  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);

  const [optionsBayers, setOptionsBayers] = useState([]);
  const [optionsBots, setOptionsBots] = useState([]);
  const [optionsChannels, setOptionsChannels] = useState([]);
  const [optionsPwas, setOptionsPwas] = useState([]);
  const [optionsDomains, setOptionsDomains] = useState([]);
  const [optionsPixels, setOptionsPixels] = useState([]);

  const watchType = watch('type');

  useEffect(() => {
    if (showCreateAppModal) {
      (async () => {
        // Получаем остальных
        const getBots = await store.botsGetListBots();
        const getBayers = await store.trafficsFacebookGetBayersList();
        const getPwas = await store.resourcePWAgetApps({
          time_config: 'current_month',
          time_start: '',
          time_end: '',
        });
        const getDomains = await store.resourceGetDomainsList('cloak');

        setOptionsDomains(
          getDomains.map((item) => ({value: item.id, label: `#${item.id} ${item.domain}`}))
        );

        const newBots = getBots.map((el) => ({
          label: el.name,
          value: el.id,
        }));

        const newBayers = getBayers.map((el) => ({
          label: `${el.first_name} ${el.last_name}`,
          value: el.id,
        }));

        const newPwas = getPwas.map((el) => ({
          label: `#${el.id} ${el.name}`,
          value: el.id,
        }));

        setOptionsBots(newBots);
        setOptionsBayers([{value: null, label: 'Не выбрано'}, ...newBayers]);
        setOptionsPwas(newPwas);
        setValue('bayer_id', null);
        setValue('pixel_id', null);
        setValue('traffic_id', traffic_id);
      })();
    }
  }, [showCreateAppModal]);

  useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      if (name === 'bot_id') {
        setOptionsChannels([]);
        getChannels(value.bot_id);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      if (name === 'type') {
        (async () => {
          const pixels = await store.getPixels(
            value.type === 'telegram_channel' ? 'telegram' : 'app'
          );
          setValue('pixel_id', null);
          setOptionsPixels([
            {label: 'Не выбрано', value: null},
            ...pixels.map((p) => ({
              value: +p.id,
              label: `#${p.id} (${p.pixel_id}) ${p.name}`,
            })),
          ]);
        })();
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const getChannels = async (bot_id) => {
    const getChannels = await store.channelsGetChannelsList('main', bot_id);

    const newChannels = getChannels.map((el) => {
      return {label: el.name, value: el.id};
    });

    setOptionsChannels(newChannels);
  };

  const onSubmit = async (data) => {
    const newData = {...data};

    // Удаляем из объекта то, что не нужно для конкретного типа
    if (data.type === 'telegram_channel') {
      delete newData.app_id;
    } else {
      delete newData.channel_id;
      delete newData.bot_id;
    }

    const result = await store.trafficsFacebookLinksAddLink(newData);

    notify(!!result, !!result ? 'Успешно сохранено!' : 'Произошла ошибка!');
    reset();
    setSave(true);
    // Обновляем данные
    if (getInfluence) {
      getInfluence(romiList, timeConfig);
    }
    setShowCreateAppModal(false);
  };

  const notify = (action, message) => {
    if (!action) {
      toast.error(`${message}`, {
        theme: 'colored',
      });
    } else {
      toast.success(`${message}`, {});
    }
  };

  return (
    <>
      <Button action={() => setShowCreateAppModal(true)}>Добавить поток</Button>
      <CreateAppModal
        customWidth='mw-550px'
        padding=''
        show={showCreateAppModal}
        handleClose={() => {
          if (save) {
            setShowCreateAppModal(false);
          } else {
            setWarning(true);
          }
        }}
        title={'Добавление потока'}
      >
        <>
          <form
            // className='form fv-plugins-bootstrap5 fv-plugins-framework'
            style={{display: 'flex', flexDirection: 'column'}}
            onSubmit={handleSubmit(onSubmit)}
          >
            {/* Название */}
            <div className='fv-row mb-7 fv-plugins-icon-container'>
              <label className='fw-semibold fs-6 mb-2'>Кампания</label>
              <input
                type='text'
                className='form-control form-control-solid mb-3 mb-lg-0 text-gray-500'
                disabled
                defaultValue={mainData?.name}
              />
            </div>
            <div className='fv-row mb-7 fv-plugins-icon-container'>
              <label className='fw-semibold fs-6 mb-2 required'>Название</label>
              <input
                type='text'
                className='form-control form-control-solid mb-3 mb-lg-0'
                placeholder='Введите название…'
                {...register('name', {required: true})}
              />
              {errors?.name?.type === 'required' && (
                <div className='fs-6 fw-semibold form-label mb-2 text-danger'>
                  Обязательное поле
                </div>
              )}
            </div>

            {/* Байер */}
            <div className='fv-row mb-7 fv-plugins-icon-container'>
              <label className='fw-semibold fs-6 mb-2 '>Байер</label>
              <Controller
                name='bayer_id'
                control={control}
                // rules={{required: true}}
                render={({field: {onChange, value}}) => (
                  <Selector
                    options={optionsBayers}
                    value={value}
                    onChange={onChange}
                    placeholder={'Не выбрано'}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        height: '42px',
                      }),
                    }}
                  />
                )}
              />
              {/* {errors?.bayer_id?.type === 'required' && (
                            <div className='fs-6 fw-semibold form-label mb-2 text-danger'>
                              Обязательное поле
                            </div>
                          )} */}
            </div>

            <div className='fv-row mb-7 fv-plugins-icon-container'>
              <label className='fw-semibold fs-6 mb-2 required'>Домен</label>
              <Controller
                name='domain_id'
                control={control}
                rules={{required: true}}
                render={({field: {onChange, value}}) => (
                  <Selector
                    options={optionsDomains}
                    value={value}
                    onChange={onChange}
                    placeholder={'Не выбрано'}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        height: '42px',
                      }),
                    }}
                  />
                )}
              />
              {errors?.domain_id?.type === 'required' && (
                <div className='fs-6 fw-semibold form-label mb-2 text-danger'>
                  Обязательное поле
                </div>
              )}
            </div>

            {/* Тип */}
            <div className='fv-row mb-7 fv-plugins-icon-container'>
              <label className='fw-semibold fs-6 mb-2 required'>Тип</label>
              <Controller
                name='type'
                control={control}
                rules={{required: true}}
                render={({field: {onChange, value}}) => (
                  <Selector
                    options={[
                      {value: 'telegram_channel', label: 'Телеграм канал'},
                      {value: 'app', label: 'Приложение'},
                    ]}
                    value={value}
                    onChange={onChange}
                    placeholder={'Не выбрано'}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        height: '42px',
                      }),
                    }}
                  />
                )}
              />
              {errors?.type?.type === 'required' && (
                <div className='fs-6 fw-semibold form-label mb-2 text-danger'>
                  Обязательное поле
                </div>
              )}
            </div>

            {/* Если telegram */}
            {watchType === 'telegram_channel' && (
              <>
                {/* Бот */}
                <div className='fv-row mb-7 fv-plugins-icon-container'>
                  <label className='fw-semibold fs-6 mb-2 required'>Бот</label>
                  <Controller
                    name='bot_id'
                    control={control}
                    rules={{required: watchType === 'telegram_channel' ? true : false}}
                    render={({field: {onChange, value}}) => (
                      <Selector
                        options={optionsBots}
                        value={value}
                        onChange={onChange}
                        placeholder={'Не выбрано'}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            height: '42px',
                          }),
                        }}
                      />
                    )}
                  />
                  {errors?.bot_id?.type === 'required' && (
                    <div className='fs-6 fw-semibold form-label mb-2 text-danger'>
                      Обязательное поле
                    </div>
                  )}
                </div>
                {/* Канал */}
                {optionsChannels.length > 0 && (
                  <div className='fv-row mb-7 fv-plugins-icon-container'>
                    <label className='fw-semibold fs-6 mb-2 required'>Канал</label>
                    <Controller
                      name='channel_id'
                      control={control}
                      rules={{required: watchType === 'telegram_channel' ? true : false}}
                      render={({field: {onChange, value}}) => (
                        <Selector
                          options={optionsChannels}
                          value={value}
                          onChange={onChange}
                          placeholder={'Не выбрано'}
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              height: '42px',
                            }),
                          }}
                        />
                      )}
                    />
                    {errors?.channel_id?.type === 'required' && (
                      <div className='fs-6 fw-semibold form-label mb-2 text-danger'>
                        Обязательное поле
                      </div>
                    )}
                  </div>
                )}
                <div className='fv-row mb-7 fv-plugins-icon-container'>
                  <label className='fw-semibold fs-6 mb-2'>Пиксель</label>
                  <Controller
                    name='pixel_id'
                    control={control}
                    render={({field: {onChange, value}}) => (
                      <Selector
                        options={optionsPixels}
                        value={value}
                        onChange={onChange}
                        placeholder={'Не выбрано'}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            height: '42px',
                          }),
                        }}
                      />
                    )}
                  />
                </div>
              </>
            )}

            {/* Если pwa */}
            {watchType === 'app' && (
              <>
                <div className='fv-row mb-7 fv-plugins-icon-container'>
                  <label className='fw-semibold fs-6 mb-2 required'>Приложения</label>
                  <Controller
                    name='app_id'
                    control={control}
                    rules={{required: watchType === 'app' ? true : false}}
                    render={({field: {onChange, value}}) => (
                      <Selector
                        options={optionsPwas}
                        value={value}
                        onChange={onChange}
                        placeholder={'Не выбрано'}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            height: '42px',
                          }),
                        }}
                      />
                    )}
                  />
                  {errors?.app_id?.type === 'required' && (
                    <div className='fs-6 fw-semibold form-label mb-2 text-danger'>
                      Обязательное поле
                    </div>
                  )}
                </div>
                <div className='fv-row mb-7 fv-plugins-icon-container'>
                  <label className='fw-semibold fs-6 mb-2'>Пиксель</label>
                  <Controller
                    name='pixel_id'
                    control={control}
                    render={({field: {onChange, value}}) => (
                      <Selector
                        options={optionsPixels}
                        value={value}
                        onChange={onChange}
                        placeholder={'Не выбрано'}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            height: '42px',
                          }),
                        }}
                      />
                    )}
                  />
                </div>
              </>
            )}

            <div className='d-flex flex-end pt-10'>
              <input
                type='submit'
                className='btn fw-bold btn-primary cursor-pointer'
                value='Создать'
              />
            </div>
          </form>
          <CreateAppModal
            customWidth='mw-400px'
            padding='pb-lg-10 pt-lg-10 px-lg-10'
            show={warning}
            handleClose={() => setWarning(false)}
            showHeader={false}
            isMessage={true}
          >
            <Warning
              buttons={[
                {
                  action: () => {
                    setWarning(false);
                  },
                  title: <i className='bi bi-x p-0 fs-1'></i>,
                  class: 'btn-light',
                },
                {
                  action: () => {
                    setWarning(false);
                    setShowCreateAppModal(false);
                    reset();
                  },
                  title: <i className='bi bi-check p-0 fs-1'></i>,
                  class: 'btn-light-primary',
                },
              ]}
            />
          </CreateAppModal>
        </>
      </CreateAppModal>
    </>
  );
};

export default observer(AddSource);
