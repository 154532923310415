import {useState, useEffect} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../../../store/store';
import {useThemeMode} from '../../../../../../_metronic/partials';
import clsx from 'clsx';
import Card from '../../../../../shared/Card';
import {
  SelectorWithSearch,
  SelectorIsMulti,
  SelectorWithCounties,
} from '../../../../../shared/Selectors';
import css from '../../../../../../styles/PwaConstructor.module.scss';

const devices = [
  {value: 'unknown', label: 'unknown'},
  {value: 'desktop', label: 'desktop'},
  {value: 'tablet', label: 'tablet'},
  {value: 'mobile', label: 'mobile'},
];
const os = [
  {value: 'unknown', label: 'unknown'},
  {value: 'windows', label: 'Windows'},
  {value: 'macos', label: 'MacOS'},
  {value: 'linux', label: 'Linux'},
  {value: 'android', label: 'Android'},
  {value: 'ios', label: 'iOS'},
];

const whitePages = [
  {label: 'Не выбрано', value: null},
  {value: 'avia', label: 'avia'},
];

const checkboxes = [
  {value: 'cloakit', label: 'Защита от ботов'},
  {value: 'vpn', label: 'VPN / Proxy'},
  {value: 'allow_ipv6', label: 'Разрешить IPv6'},
  {value: 'check_pixel', label: 'Верификация FB Pixel'},
  {value: 'check_fbclid', label: 'Проверка fbclid'},
  {value: 'check_campaign_id', label: 'Проверка Campaign ID'},
  {value: 'check_adset_id', label: 'Проверка AdSet ID'},
  {value: 'check_ad_id', label: 'Проверка Ad ID'},
  {value: 'check_placement', label: 'Проверка Placement'},
];

const Clo = ({saveData, setSaveData, dataForSelectors, validation, setSaveButtonState}) => {
  const store = useStore();
  const theme = useThemeMode();

  const [themeMode, setThemeMode] = useState('');
  const [active, setActive] = useState({
    os: true,
    deviceType: true,
    countries: true,
  });

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setThemeMode('dark');
      } else {
        setThemeMode('light');
      }
    } else {
      setThemeMode(theme.mode);
    }
  }, [theme]);

  // useEffect(() => {
  //   console.log(saveData);
  // }, [saveData]);

  const findValues = (options, field, field2) => {
    const values = options?.filter((item) => {
      const opt =
        saveData[field] && saveData[field][field2]?.length > 0 ? saveData[field][field2] : [];
      let res = false;
      opt?.forEach((el) => {
        if (item.value === el) {
          res = true;
        }
      });
      if (res) {
        res = false;
        return item;
      }
    });
    return values;
  };

  return (
    <Card className='card'>
      <div className='card-header'>
        <div
          className='card-title fs-3 fw-bold'
          style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}
        >
          <span>Клоака</span>
        </div>
      </div>
      <div
        className={clsx('card-body', css.form)}
        style={{
          '--text-1': themeMode === 'dark' ? 'rgba(245, 245, 245, 1)' : 'rgba(31, 33, 41, 1)',
          '--text-2': themeMode === 'dark' ? 'rgba(100, 102, 116, 1)' : 'rgba(152, 161, 183, 1)',
          '--bg': themeMode === 'dark' ? 'rgba(21, 23, 28, 1)' : 'rgba(255, 255, 255, 1)',
          '--activeColor': themeMode === 'dark' ? 'rgba(27, 132, 255, 1)' : 'rgba(27, 132, 255, 1)',
          '--inputBg': themeMode === 'dark' ? 'rgba(27, 28, 33, 1)' : 'rgba(249, 249, 249, 1)',
          '--colorLine': themeMode === 'dark' ? 'rgba(53, 57, 66, 1)' : 'rgba(225, 227, 234, 1)',
          '--buttonGrayBg': themeMode === 'dark' ? 'rgba(27, 28, 33, 1)' : 'rgba(241, 241, 244, 1)',
          '--slideSelectorBg':
            themeMode === 'dark' ? 'rgba(27, 28, 33, 1)' : 'rgba(241, 243, 244, 1)',
          '--slideSelectorItemBg':
            themeMode === 'dark' ? 'rgba(42, 46, 54, 1)' : 'rgba(255, 255, 255, 1)',
          '--buttonCommentBg':
            themeMode === 'dark' ? 'rgba(24, 35, 49, 1)' : 'rgba(232, 242, 254, 1)',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap-reverse',
            gap: '50px',
          }}
        >
          <div
            className='col-12 col-xl-6'
            style={{
              gap: '24px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{width: '100%'}}>
              <span style={{marginBottom: '5px', display: 'inline-block'}}>White Page</span>
              <SelectorWithSearch
                value={whitePages.find((d) => d.value === saveData.whitepage_id)}
                options={whitePages}
                setValue={(e) => {
                  setSaveData((prev) => ({...prev, whitepage_id: e.value}));
                  setSaveButtonState(1);
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '42px',
                    width: '100%',
                    border: validation.includes('whitepage_id') && '2px solid red !important',
                    boxShadow: validation.includes('whitepage_id') && 'none',
                  }),
                }}
              />
            </div>
            <div style={{width: '100%'}}>
              <span style={{display: 'flex', marginBottom: '8px', alignItems: 'center', gap: 12}}>
                OC
                <span
                  style={{cursor: 'pointer'}}
                  className={`badge ${
                    saveData?.os_permissions?.is_allowed
                      ? 'badge-light-success'
                      : 'badge-light-danger'
                  }`}
                  onClick={() => {
                    setSaveData({
                      ...saveData,
                      os_permissions: {
                        systems: saveData?.os_permissions?.systems || [],
                        is_allowed: !saveData?.os_permissions?.is_allowed,
                      },
                    });
                    setSaveButtonState(1);
                  }}
                >
                  {saveData?.os_permissions?.is_allowed ? 'разрешено' : 'запрещено'}
                </span>
              </span>
              <SelectorIsMulti
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '42px',
                    boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                  }),
                }}
                options={os}
                value={findValues(os, 'os_permissions', 'systems')}
                onChange={(e) => {
                  setSaveData({
                    ...saveData,
                    os_permissions: {
                      ...saveData?.os_permissions,
                      systems: e.map((item) => item.value),
                    },
                  });
                  setSaveButtonState(1);
                }}
              />
            </div>
            <div style={{width: '100%'}}>
              <span style={{display: 'flex', marginBottom: '8px', alignItems: 'center', gap: 12}}>
                Тип устройства
                <span
                  style={{cursor: 'pointer'}}
                  className={`badge ${
                    saveData?.devices_permissions?.is_allowed
                      ? 'badge-light-success'
                      : 'badge-light-danger'
                  }`}
                  onClick={() => {
                    setSaveData({
                      ...saveData,
                      devices_permissions: {
                        devices: saveData?.devices_permissions?.devices || [],
                        is_allowed: !saveData?.devices_permissions?.is_allowed,
                      },
                    });
                    setSaveButtonState(1);
                  }}
                >
                  {saveData?.devices_permissions?.is_allowed ? 'разрешено' : 'запрещено'}
                </span>
              </span>
              <SelectorIsMulti
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    minHeight: '42px',
                    height: 'fit-content',
                    boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                  }),
                }}
                options={devices}
                value={findValues(devices, 'devices_permissions', 'devices')}
                onChange={(e) => {
                  setSaveData({
                    ...saveData,
                    devices_permissions: {
                      ...saveData?.devices_permissions,
                      devices: e.map((item) => item.value),
                    },
                  });
                  setSaveButtonState(1);
                }}
              />
            </div>
            <div style={{width: '100%'}}>
              <span style={{display: 'flex', marginBottom: '8px', alignItems: 'center', gap: 12}}>
                Страны
                <span
                  style={{cursor: 'pointer'}}
                  className={`badge ${
                    saveData?.countries_permissions?.is_allowed
                      ? 'badge-light-success'
                      : 'badge-light-danger'
                  }`}
                  onClick={() => {
                    setSaveData({
                      ...saveData,
                      countries_permissions: {
                        countries: saveData?.countries_permissions?.countries || [],
                        is_allowed: !saveData?.countries_permissions?.is_allowed,
                      },
                    });
                    setSaveButtonState(1);
                  }}
                >
                  {saveData?.countries_permissions?.is_allowed ? 'разрешено' : 'запрещено'}
                </span>
              </span>
              <SelectorWithCounties
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                  }),
                }}
                options={dataForSelectors?.countries}
                value={findValues(
                  dataForSelectors?.countries,
                  'countries_permissions',
                  'countries'
                )}
                setValue={(e) => {
                  setSaveData({
                    ...saveData,
                    countries_permissions: {
                      ...saveData?.countries_permissions,
                      countries: e.map((item) => item.value),
                    },
                  });
                  setSaveButtonState(1);
                }}
                isMulti={true}
              />
            </div>
          </div>

          <div
            className='col-12 col-xl-6'
            style={{
              flex: '1',
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, 235px)',
              gap: '16px',
            }}
          >
            {checkboxes.map((item, index) => {
              return (
                <div
                  key={`${item.value}-${index}`}
                  style={{
                    maxWidth: '400px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    gap: '16px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <span>{item.label}</span>
                  <label className={css.switch} htmlFor={`${item.value}-${index}`}>
                    <input
                      type='checkbox'
                      id={`${item.value}-${index}`}
                      checked={saveData[`${item.value}`]}
                      onChange={(e) => {
                        const newSaveData = {...saveData};
                        newSaveData[`${item.value}`] = !newSaveData[`${item.value}`];
                        setSaveData(newSaveData);
                        setSaveButtonState(1);
                      }}
                    />
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default observer(Clo);
