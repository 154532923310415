/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {useState, useEffect} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../store/store';
import numeral from 'numeral';
import moment from 'moment';
import Finances from '../../../widgets/Finances';
import Chart from '../../../widgets/Chart';
import PageWrapper from '../../../modules/PageWrapper';
import {
  formatNumber,
  formatNumberToCash,
  formatNumberToCash2,
} from '../../../functions/formatNumber';
import {SelectorWithSearch} from '../../../shared/Selectors';
import {useThemeMode} from '../../../../_metronic/partials';
import ButtonExelForTable from '../../../modules/ButtonExelForTable';
import {ReactComponent as Coin} from './assets/coin.svg';
import {ReactComponent as Receive} from './assets/card-receive.svg';
import {ReactComponent as Send} from './assets/card-send.svg';
import {ReactComponent as Percent} from './assets/discount-shape.svg';

const DashboardPage = observer(({timeConfig, isVerify, setPreloader}) => {
  const store = useStore();
  const theme = useThemeMode();
  const [mode, setMode] = useState('');

  const [advertisers, setAdvertisers] = useState([]);
  const [offers, setOffers] = useState([]);
  const [bots, setBots] = useState([]);
  const [channels, setChannels] = useState([]);

  const [advertiser, setAdvertiser] = useState({});
  const [offer, setOffer] = useState({});
  const [bot, setBot] = useState({});
  const [channel, setChannel] = useState({});

  const [params, setParams] = useState({});

  const [salesStats, setSalesStats] = useState({});

  const [qualificationSchedule, setQualificationSchedule] = useState([]);

  const [costsSchedule, setCostsSchedule] = useState([]);
  const [time, setTime] = useState(null);

  useEffect(() => {
    let queryStr = '';
    for (let [key, value] of Object.entries(params)) {
      queryStr += `${key}=${value}`;
    }
    isVerify && timeConfig.time_config && getData(queryStr);
  }, [isVerify, timeConfig]);

  useEffect(() => {
    isVerify && getFilters();
  }, [isVerify]);

  const getFilters = async () => {
    const [getAdvertisers, getOffers, getBots, getChannels] = await Promise.all([
      store.getAdvertisersList(),
      store.offersGetOffersList(),
      store.botsGetListBots(),
      store.channelsGetChannelsList(''),
    ]);

    setAdvertisers(
      getAdvertisers.map((item) => {
        return {value: item.id, label: `${item.name} #${item.id}`};
      })
    );
    setOffers(
      getOffers.map((item) => {
        return {value: item.id, label: `${item.name} #${item.id}`};
      })
    );
    setBots(
      getBots.map((item) => {
        return {value: item.id, label: `${item.name} #${item.id}`};
      })
    );
    setChannels(
      getChannels.map((item) => {
        return {value: item.id, label: `${item.name} #${item.id}`};
      })
    );
    setPreloader(false);
  };

  const createParams = async (field, value) => {
    const newParams = {...params, [field]: value};
    setParams(newParams);
    let queryStr = '';
    for (let [key, value] of Object.entries(newParams)) {
      queryStr += `${key}=${value}`;
    }

    getData(queryStr);
  };

  const deleteParams = async (field) => {
    const newParams = {...params};
    delete newParams[field];
    setParams(newParams);
    let queryStr = '';
    for (let [key, value] of Object.entries(newParams)) {
      queryStr += `${key}=${value}`;
    }

    getData(queryStr);
  };

  const getData = async (filter = '') => {
    const [getStats, qualifications, getCostSchedule] = await Promise.all([
      store.getMainStatsFacebook(timeConfig, filter),
      store.getQualificationScheduleFacebook(timeConfig, filter),
      store.facebookGetCostSchedule(timeConfig, filter),
    ]);
    setQualificationSchedule(qualifications);
    console.log(getStats)
    setSalesStats(getStats.data);
    setTime(getStats.time);
    setCostsSchedule(getCostSchedule);
    setPreloader(false);
  };

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setMode('dark');
      } else {
        setMode('light');
      }
    } else {
      setMode(theme.mode);
    }
  }, [theme]);

  return (
    <>
      <div className='filtersSalesPanel g-5 g-xl-10 my-5'>
        <label
          htmlFor='filtersSalesPanel'
          className='filtersSalesPanel_activator fw-bold text-gray-800'
          style={{
            backgroundColor: `${mode === 'dark' ? '#15171C' : '#ffffff'}`,
          }}
        >
          Фильтра
          <input className='' type='checkbox' name='' id='filtersSalesPanel' />
          <i className='ki-duotone ki-down fs-1'></i>
          <i className='ki-duotone ki-up fs-1'></i>
        </label>
        <div className='filtersSalesPanel_item'>
          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '42px',
                boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                backgroundColor: mode === 'dark' ? '#15171C !important' : '#ffffff !important',
              }),
            }}
            placeholder='Рекламодатель...'
            options={advertisers}
            actions={(e) => {
              setAdvertiser(e);
              if (e === null) {
                return deleteParams('&advertiser_id');
              }
              createParams('&advertiser_id', e.value);
            }}
            value={advertiser}
            clearable={true}
          />
        </div>
        <div className='filtersSalesPanel_item'>
          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '42px',
                boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                backgroundColor: mode === 'dark' ? '#15171C !important' : '#ffffff !important',
              }),
            }}
            placeholder='Офферы...'
            options={offers}
            actions={(e) => {
              setOffer(e);
              if (e === null) {
                return deleteParams('&offer_id');
              }
              createParams('&offer_id', e.value);
            }}
            value={offer}
            clearable={true}
          />
        </div>
        <div className='filtersSalesPanel_item'>
          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '42px',
                boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                backgroundColor: mode === 'dark' ? '#15171C !important' : '#ffffff !important',
              }),
            }}
            placeholder='Боты...'
            options={bots}
            actions={(e) => {
              setBot(e);
              if (e === null) {
                return deleteParams('&bot_id');
              }
              createParams('&bot_id', e.value);
            }}
            value={bot}
            clearable={true}
          />
        </div>
        <div className='filtersSalesPanel_item'>
          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '42px',
                boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                backgroundColor: mode === 'dark' ? '#15171C !important' : '#ffffff !important',
              }),
            }}
            placeholder='Каналы...'
            options={channels}
            actions={(e) => {
              setChannel(e);
              if (e === null) {
                return deleteParams('&channel_id');
              }
              createParams('&channel_id', e.value);
            }}
            value={channel}
            clearable={true}
          />
        </div>
      </div>
      {!store.roles.includes(19) && !store.roles.includes(6) && (
        <div className='row g-4 mb-4'>
          <div className='col-6 col-md-3'>
            <div className='card p-4 card-flush'>
              <div className='d-flex fw-bold gap-2 align-items-center' style={{fontSize: '20px'}}>
                <Coin />
                Доход
              </div>
              <div
                className='text-gray-700'
                style={{
                  fontSize: '18px',
                  fontWeight: '600',
                  marginTop: '20px',
                }}
              >
                {formatNumberToCash2(salesStats?.revenue)}
              </div>
            </div>
          </div>
          <div className='col-6 col-md-3'>
            <div className='card p-4 card-flush'>
              <div className='d-flex fw-bold gap-2 align-items-center' style={{fontSize: '20px'}}>
                <Receive />
                Прибыль
              </div>
              <div
                className='text-gray-700'
                style={{
                  fontSize: '18px',
                  fontWeight: '600',
                  marginTop: '20px',
                }}
              >
                {formatNumberToCash2(salesStats?.profit)}
              </div>
            </div>
          </div>
          <div className='col-6 col-md-3'>
            <div className='card p-4 card-flush'>
              <div className='d-flex fw-bold gap-2 align-items-center' style={{fontSize: '20px'}}>
                <Send />
                Расходы
              </div>
              <div
                className='text-gray-700'
                style={{
                  fontSize: '18px',
                  fontWeight: '600',
                  marginTop: '20px',
                }}
              >
                {formatNumberToCash2(salesStats?.cost)}
              </div>
            </div>
          </div>
          <div className='col-6 col-md-3'>
            <div className='card p-4 card-flush'>
              <div className='d-flex fw-bold gap-2 align-items-center' style={{fontSize: '20px'}}>
                <Percent />
                ROMI
              </div>
              <div
                className='text-gray-700'
                style={{fontSize: '18px', fontWeight: '600', marginTop: '20px'}}
              >
                {formatNumber(salesStats?.romi)}%
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='row g-5 g-xl-10 mt-0'>
        <div className='col-xl-4 mb-xl-10 mt-0'>
          <Finances
            background={'fb'}
            title='Статистика'
            subTitle='Управляйте периодом в календаре.'
            stats={[
              {
                title: 'Пользователей',
                number: formatNumber(salesStats?.count_leads) || 0,
                icon: (
                  <i className='ki-duotone ki-fingerprint-scanning fs-1 text-primary'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                    <span className='path4'></span>
                    <span className='path5'></span>
                  </i>
                ),
              },
              {
                title: 'Регистрации',
                number: formatNumber(salesStats?.count_registrations) || 0,
                icon: (
                  <i className='ki-duotone ki-user-edit fs-1 text-primary'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                ),
              },
              {
                title: 'Обращения',
                number: formatNumber(salesStats?.count_appeals) || 0,
                icon: (
                  <i className='ki-duotone ki-wallet fs-1 text-primary'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                    <span className='path4'></span>
                  </i>
                ),
              },
              {
                title: 'Квалификации',
                number: formatNumber(salesStats?.count_qualifications) || 0,
                icon: (
                  <i className='ki-duotone ki-user-tick fs-1 text-primary'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                ),
              },
            ]}
          />
        </div>

        <div className='col-xl-8 mb-5 mb-xl-10 mt-xl-0'>
          <Chart data={{graph: qualificationSchedule}} />
        </div>
      </div>

      <div className='row g-5 g-xl-10 mt-0'>
        <div className='col-xl-12 mb-5 mb-xl-10 mt-xl-0'>
          <div className='card mb-5 mt-0 mb-xl-8'>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                {/* <span className='card-label fw-bold fs-3 mb-1'>Приложения</span> */}
              </h3>
              <div style={{display: 'flex', gap: '10px'}}>
                <ButtonExelForTable
                  data={costsSchedule.map((item) => {
                    return {...item, day: moment(item?.day * 1000).format('ll')};
                  })}
                  fieldsToKeep={[
                    'day',
                    'count_click',
                    'count_leads',
                    'count_registrations',
                    'count_qualifications',
                    'revenue',
                    'cost',
                    'count_first_deposit',
                  ]}
                  columnFormats={{revenue: '$#,##0.00', count_cost: '$#,##0.00'}}
                  fileName={'companies'}
                  time={time}
                />
              </div>
            </div>

            <div className='card-body py-3'>
              <div className='table-responsive' style={{maxHeight: '800px'}}>
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-3'>
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-120px'>Дата</th>
                      <th className='min-w-120px text-center'>Клики</th>
                      <th className='min-w-120px text-center'>Установки(Лиды)</th>
                      <th className='min-w-120px text-center'>Регистрации</th>
                      <th className='min-w-120px text-center'>Первые депозиты</th>
                      <th className='min-w-120px text-center'>Квалификации</th>
                      {!store.roles.includes(6) && (
                        <th className='min-w-120px text-center'>Доход</th>
                      )}
                      {!store.roles.includes(6) && (
                        <th className='min-w-120px text-center'>Расходы</th>
                      )}
                    </tr>
                  </thead>

                  <tbody className='text-gray-600 fw-semibold'>
                    {costsSchedule?.map((el) => {
                      return (
                        <tr key={el?.day}>
                          <td>
                            <span>{moment(el?.day * 1000).format('ll')}</span>
                          </td>
                          <td>
                            <span className='text-gray-900 fw-bold text-hover-primary d-block text-center mb-1 fs-6'>
                              {el?.count_click}
                            </span>
                          </td>
                          <td>
                            <span className='text-gray-900 fw-bold text-hover-primary d-block text-center mb-1 fs-6'>
                              {el?.count_leads}
                            </span>
                          </td>
                          <td>
                            <span className='text-gray-900 fw-bold text-hover-primary d-block text-center mb-1 fs-6'>
                              {el?.count_registrations}
                            </span>
                          </td>
                          <td>
                            <span className='text-gray-900 fw-bold text-hover-primary d-block text-center mb-1 fs-6'>
                              {el?.count_first_deposit}
                            </span>
                          </td>
                          <td>
                            <span className='text-gray-900 fw-bold text-hover-primary d-block text-center mb-1 fs-6'>
                              {el?.count_qualifications}
                            </span>
                          </td>
                          {!store.roles.includes(6) && (
                            <td>
                              <span className='text-gray-900 fw-bold text-hover-primary d-block text-center mb-1 fs-6'>
                                {formatNumberToCash(el?.revenue)}
                              </span>
                            </td>
                          )}
                          {!store.roles.includes(6) && (
                            <td>
                              <span className='text-gray-900 fw-bold text-hover-primary d-block text-center mb-1 fs-6'>
                                {formatNumberToCash(el?.cost)}
                              </span>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

const FacebookPanel = () => {
  return (
    <PageWrapper
      firstTimeConfig='current_month'
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Панель', path: '/dashboard/facebook', isActive: true},
      ]}
      title={'Панель Facebook'}
    >
      <DashboardPage />
    </PageWrapper>
  );
};

export default FacebookPanel;
