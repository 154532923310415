/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import moment from 'moment';
import {toast} from 'react-toastify';
import {CreateAppModal} from '../../../../_metronic/partials';
import {Button} from '../../../shared/Buttons';
import imagePlug from '../../../../_metronic/assets/media/user.png';
import InputSearch from '../../../shared/InputSearch';
import Card from '../../../shared/Card';
import PageWrapper from '../../../modules/PageWrapper';
import AddBot from './AddBot';

const BotsWrapper = () => {
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Боты', path: '/resources/telegram/bots', isActive: true},
      ]}
      title={'Боты'}
    >
      <Bots />
    </PageWrapper>
  );
};

const Bots = observer(({isVerify, setPreloader}) => {
  const store = useStore();

  const [bots, setBots] = useState([]);
  const [searchBots, setSearchBots] = useState([]);

  useEffect(() => {
    isVerify && getBots();
  }, [isVerify]);

  const getBots = async () => {
    const result = await store.botsGetBots();

    setSearchBots([...result]);
    setBots([...result]);
    setPreloader(false);
  };

  return (
    <>
      <Card className='card'>
        <div className='card-header border-0 pt-6'>
          <div className='card-title'>
            <InputSearch
              title={'Поиск...'}
              array={bots}
              newArray={setSearchBots}
              searchParameters={['username', 'id', 'name']}
            />
          </div>

          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              <ButtonAdd getBots={getBots} />
            </div>
          </div>
        </div>
        <Table bots={bots} getBots={getBots} searchBots={searchBots} />
      </Card>
    </>
  );
});

const Table = ({bots, getBots, searchBots}) => {
  // const [portionBots, setPortionBots] = useState([]);
  // const [limit, setLimit] = useState(10);
  // const [offset, setOffset] = useState(1);

  // useEffect(() => {
  //   const newBots = [];
  //   for (let i = limit * offset - limit; i < limit * offset; i++) {
  //     if (bots.length > i) {
  //       newBots.push(bots[i]);
  //     }
  //   }
  //   setPortionBots(newBots);
  // }, [offset, bots]);

  return (
    <div className='card-body py-4'>
      <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
        <div className='table-responsive'>
          <table className='table align-middle table-row-dashed fs-6 gy-5'>
            <thead>
              <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                <th className='min-w-125px'>бот</th>
                <th className='min-w-125px'>оффер</th>
                <th className='min-w-125px'>название</th>
                <th className='min-w-125px'>создан</th>
                <th className='text-end min-w-100px'>Действие</th>
              </tr>
            </thead>

            <tbody className='text-gray-600 fw-semibold'>
              {searchBots.map((el, i) => {
                return <Bot key={el.id} data={el} getBots={getBots} />;
              })}
            </tbody>
          </table>
        </div>
      </div>
      {/* {bots.length > limit && (
        <Pagination arrLength={bots.length} limit={limit} offset={offset} setOffset={setOffset} />
      )} */}
    </div>
  );
};

const Bot = ({data, getBots}) => {
  return (
    <tr>
      <td>
        <div className='d-flex align-items-center'>
          <div className='d-flex flex-column justify-content-center'>
            <span className='fs-6 text-gray-800 text-hover-primary'>{data.username}</span>
            <div className='fw-semibold text-gray-400'>#{data.id}</div>
          </div>
        </div>
      </td>
      <td className='d-flex align-items-center'>
        <div className='d-flex align-items-center'>
          <div className='me-5 position-relative'>
            <div className='symbol symbol-35px symbol-circle'>
              <img
                src={
                  data.offer.avatar
                    ? `${process.env.REACT_APP_API_HOST}/${data.offer.avatar}`
                    : imagePlug
                }
                alt=''
              />
            </div>
          </div>
          <div className='d-flex flex-column justify-content-center'>
            <span className='fs-6 text-gray-800 text-hover-primary'>{data.offer.name}</span>
            <div className='fw-semibold text-gray-400'>#{data.offer.id}</div>
          </div>
        </div>
      </td>
      <td>
        <div className='badge badge-light fw-bold'>Название Бота ({data.name})</div>
      </td>
      <td>{moment(data.created_at * 1000).format('DD.MM.YYYY, HH:mm')}</td>
      <td className='text-end'>
        <div className='d-flex justify-content-end flex-shrink-0'>
          <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
            <i className='ki-duotone ki-switch fs-2'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
          </span>

          <ButtonUpdate updateData={data} getBots={getBots} />
        </div>
      </td>
    </tr>
  );
};

export const ButtonAdd = ({getBots}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);

  return (
    <>
      <Button
        action={() => {
          setShowCreateAppModal(true);
          setSave(false);
        }}
      >
        <>
          <i className='ki-duotone ki-plus fs-2'></i>Добавить
        </>
      </Button>
      <CreateAppModal
        customWidth='mw-550px'
        show={showCreateAppModal}
        handleClose={() => {
          if (save) {
            setShowCreateAppModal(false);
          } else {
            setWarning(true);
          }
        }}
        title={'Добавление бота'}
      >
        <AddBot
          setWarning={setWarning}
          warning={warning}
          setSave={setSave}
          save={save}
          show={setShowCreateAppModal}
          getBots={getBots}
          isUpdate={false}
        />
      </CreateAppModal>
    </>
  );
};

const ButtonUpdate = ({updateData, getBots}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);

  return (
    <>
      <span
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        onClick={() => {
          setShowCreateAppModal(true);
          setSave(false);
        }}
      >
        <i className='ki-duotone ki-pencil fs-2'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>
      </span>

      <CreateAppModal
        customWidth='mw-550px'
        show={showCreateAppModal}
        handleClose={() => {
          if (save) {
            setShowCreateAppModal(false);
          } else {
            setWarning(true);
          }
        }}
        title={'Редактирование бота'}
      >
        <AddBot
          setWarning={setWarning}
          warning={warning}
          setSave={setSave}
          save={save}
          show={setShowCreateAppModal}
          getBots={getBots}
          isUpdate={true}
          updateData={updateData}
        />
      </CreateAppModal>
    </>
  );
};

export default BotsWrapper;
