/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useStore} from '../../../store/store';
import {observer} from 'mobx-react';
import numeral from 'numeral';
import moment from 'moment';
import imagePlug from '../../../_metronic/assets/media/user.png';
import {Button} from '../../shared/Buttons';
import ModeToolbar from '../../modules/ModeToolbar';
import {PageTitle} from '../../../_metronic/layout/core';
import Navigation from '../../modules/NavigationBloggers';
import Card from '../../shared/Card';

import Info from './Info';
import Registration from './Registration';
import Deposits from './Deposits';
import Log from './Log';
import Appoint from './Appoint';
import Chats from './Chats';

import PageWrapper from '../../modules/PageWrapper';
import {toast} from 'react-toastify';

const LeadWrapper = () => {
  const {id} = useParams();

  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Лиды', path: '/leads', isActive: true},
        {isSeparator: true},
        {title: `#${id}`, path: `/traffic/leads/${id}`, isActive: false},
      ]}
      title={`#${id}`}
    >
      <Lead id={id} />
    </PageWrapper>
  );
};

const Lead = observer(({isVerify, setPreloader, id}) => {
  const store = useStore();

  const [data, setData] = useState();
  const [registrations, setRegistrations] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [log, setLog] = useState({});
  const [chats, setChats] = useState([]);

  const [page, setPage] = useState('info');

  useEffect(() => {
    isVerify && getData();
  }, [isVerify]);

  useEffect(() => {
    if (page === 'registration') {
      getRegistration();
    } else if (page === 'deposits') {
      getDeposits();
    } else if (page === 'log') {
      getLog();
    } else if (page === 'chats') {
      getChats();
    }
  }, [page]);

  const getData = async () => {
    const getLead = await store.leadsGetLead(id);

    const getStatuses = await store.leadsGetStatuses();
    const findStatus = getStatuses.find((item) => +item.status === +getLead.status);

    const getCountries = await store.getCountries();
    const finCountry = getCountries.find((item) => item.id === getLead.countries_id);

    setData({...getLead, country: finCountry, status: findStatus});
    setPreloader(false);
  };

  const getRegistration = async () => {
    const getLeadRegistrations = await store.leadsGetLeadRegistrations(id);
    setRegistrations(getLeadRegistrations);
  };

  const getDeposits = async () => {
    const getLeadDeposits = await store.leadsGetLeadDeposits(id);
    setDeposits(getLeadDeposits);
  };

  const getLog = async () => {
    const getLeadTransfers = await store.leadsTranfersGetLead(id);
    setLog(getLeadTransfers);
  };

  const getChats = async () => {
    const getLeadChats = await store.leadsGetChats(id);
    setChats(getLeadChats);
  };
  const checkData = async () => {
    const result = await store.leadsGetAdvertisersUpdateLead(id);

    const getStatuses = await store.leadsGetStatuses();
    const findStatus = getStatuses.find((item) => +item.status === +result.status);

    const getCountries = await store.getCountries();
    const finCountry = getCountries.find((item) => item.id === result.countries_id);

    !!result &&
      toast.success('Успешно обновлено!', {}) &&
      setData({...result, country: finCountry, status: findStatus});
    !result &&
      toast.error(`Произошла ошибка!`, {
        theme: 'colored',
      });
  };
  return (
    <>
      <Header data={data} setPage={setPage} page={page} getData={getData} checkData={checkData} />
      {page === 'info' && <Info data={data} />}
      {page === 'chats' && <Chats data={chats} />}
      {page === 'registration' && <Registration data={registrations} leadId={data?.lead_id} />}
      {page === 'deposits' && <Deposits data={deposits} leadId={data?.lead_id} />}
      {page === 'log' && <Log data={log} />}
    </>
  );
});

const Header = observer(({data, setPage, page, getData, checkData}) => {
  const store = useStore();
  return (
    <>
      <Card className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-6'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img
                  src={
                    data?.offer?.avatar
                      ? `${process.env.REACT_APP_API_HOST}/${data?.offer?.avatar}`
                      : imagePlug
                  }
                  alt=''
                />
              </div>
            </div>

            <div className='flex-grow-1'>
              <div className='d-md-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-1'>
                    <span className='text-gray-800 text-hover-primary fs-2 fw-bold me-3'>
                      {!!data?.lead_id ? `#${data?.lead_id}` : 'Не определено'}
                    </span>
                    <span className={`badge badge-light-${data?.status?.indicator} me-auto`}>
                      {data?.status?.name}
                    </span>
                  </div>

                  <div className='d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-400'>
                    <span className='d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2'>
                      <i className='ki-duotone ki-profile-circle fs-4 me-1'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                      {!!data?.platform_id ? `#${data?.platform_id}` : 'Не определено'}
                    </span>
                    {/* <span className='d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2'>
                      <i className='ki-duotone ki-geolocation fs-4 me-1'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                      Facebook
                    </span> */}
                  </div>
                </div>
                {data?.offer?.name?.toLowerCase().includes('1win') && (
                  <div className='d-flex flex-wrap mb-4'>
                    <Button className='me-3' action={checkData}>
                      Проверить данные
                    </Button>
                  </div>
                )}
              </div>

              <div className='d-flex flex-wrap justify-content-start'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-4 fw-bold'>
                        {moment(new Date(data?.created_at * 1000)).format('ll')}
                      </div>
                    </div>

                    <div className='fw-semibold fs-6 text-gray-400'>Создан</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      {Number(data?.amount) >= 50 && (
                        <i className='ki-duotone ki-arrow-up fs-3 text-success me-2'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      )}
                      {Number(data?.amount) < 50 && (
                        <i className='ki-duotone ki-arrow-down fs-3 text-danger me-2'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      )}
                      <div className='fs-4 fw-bold'>{data?.amount}$</div>
                    </div>

                    <div className='fw-semibold fs-6 text-gray-400'>Сумма депозитов</div>
                  </div>

                  {/* <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <i className='ki-duotone ki-arrow-up fs-3 text-success me-2'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                      <div
                        className='fs-4 fw-bold'
                        data-kt-countup='true'
                        data-kt-countup-value='15000'
                        data-kt-countup-prefix='$'
                      >
                        {60}%
                      </div>
                    </div>

                    <div className='fw-semibold fs-6 text-gray-400'>Качество лида</div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <Navigation
            page={page}
            setPage={setPage}
            links={[
              {link: 'info', title: 'Информация'},
              {link: 'chats', title: 'Чаты'},
              {link: 'registration', title: 'Регистрации'},
              {link: 'deposits', title: 'Депозиты'},
              {link: 'log', title: 'Логирование'},
            ]}
          />
        </div>
      </Card>
    </>
  );
});

export default LeadWrapper;
