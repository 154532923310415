/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import {useParams, Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import moment from 'moment';
import {KTIcon} from '../../../../_metronic/helpers';
import {CreateAppModal} from '../../../../_metronic/partials';
import {Button} from '../../../shared/Buttons';
import Card from '../../../shared/Card';
import AddDomain from './AddDomain';
import PageWrapper from '../../../modules/PageWrapper';

const DomainWrapper = () => {
  const {id} = useParams();

  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Домены', path: '/resources/domains'},
        {isSeparator: true},
        {
          title: `Домен #${id}`,
          path: `/domains/${id}`,
          isActive: true,
        },
      ]}
      title={'Домен'}
    >
      <Domain />
    </PageWrapper>
  );
};

const Domain = observer(({isVerify, setPreloader}) => {
  moment.locale('ru');
  const store = useStore();
  const {id} = useParams();

  const [data, setData] = useState({});

  useEffect(() => {
    if (!isVerify) return;
    (async () => {
      const getDomain = await store.resourceGetDomain(id);
      setData(getDomain);
      setPreloader(false);
    })();
  }, [isVerify]);

  const copyText = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success(`Скопировано!`, {});
      })
      .catch((err) => {
        console.log('Something went wrong', err);
      });
  };

  const period = (start = new Date().getTime(), end = new Date().getTime()) => {
    const startDate = moment(start);
    const endDate = moment(end);
    const durationInDays = endDate.diff(startDate, 'days');
    let title;

    if (durationInDays < 1) {
      title = 'дней';
    } else if (durationInDays === 1) {
      title = 'день';
    } else if (durationInDays === 2 || durationInDays === 3 || durationInDays === 4) {
      title = 'дня';
    } else {
      title = 'дней';
    }

    return `${durationInDays} ${title}`;
  };

  return (
    <>
      <div className='row g-5 g-xl-10 mt-0'>
        <div className='col-xl-7 mb-5 mb-xl-10 mt-0'>
          <Card className='card mb-5 mb-xl-10'>
            <div className='card-header py-7'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold text-gray-800'>{data?.domain}</span>
                <span className='text-gray-400 pt-2 fw-semibold fs-6'>Информация о домене</span>
              </h3>
              <div className='card-toolbar'>
                <ButtonUpdate data={data} />
              </div>
            </div>
            <div className='card-body p-9'>
              <div className='row mb-7'>
                <label className='col-4 fw-semibold text-muted'>ID</label>

                <div className='col-8 d-flex align-items-center'>
                  <span className='fw-bold fs-6 text-gray-800 me-2'>#{data?.id}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-4 fw-semibold text-muted'>Домен</label>

                <div className='col-8 d-flex align-items-center'>
                  <span className='fw-bold fs-6 text-gray-800 me-2'>{data?.domain}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-4 fw-semibold text-muted'>Конфиг</label>

                <div className='col-8 d-flex align-items-center'>
                  <span className='fw-bold fs-6 text-gray-800 me-2'>
                    {data?.config === 'main' ? 'Основной' : 'Субдомен'}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-4 fw-semibold text-muted'>Статус</label>

                <div className='col-8 d-flex align-items-center'>
                  <span
                    className={`badge ${
                      !data.status ? 'badge-light-danger' : 'badge-light-success'
                    }`}
                  >
                    {!!data?.status ? 'Активный' : 'Не активный'}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-4 fw-semibold text-muted'>Дата создания</label>

                <div className='col-8 d-flex align-items-center'>
                  <span className='fw-bold fs-6 text-gray-800 me-2'>
                    {moment(data?.created_at * 1000).format('DD.MM.YYYY')}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-4 fw-semibold text-muted'>Действитеден до</label>

                <div className='col-8 d-flex align-items-center'>
                  <span className='fw-bold fs-6 text-gray-800 me-2'>
                    {moment(data?.created_at * 1000).format('DD.MM.YYYY')}{' '}
                    <span className={`badge  badge-light`}>
                      {period(new Date().getTime(), data?.valid)}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className='col-xl-5 mb-5 mb-xl-10 mt-0'>
          <Card className='card mb-5 mb-xl-10'>
            <div className='card-header py-7'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold text-gray-800'>Сервер</span>
              </h3>
              <div className='card-toolbar'>
                <Link to={`/servers/${data?.server?.id}`}>
                  <span className={`badge badge-light-primary cursor-pointer`}>Подробнее</span>
                </Link>
              </div>
            </div>
            <div className='card-body p-9'>
              <div className='row mb-7'>
                <label className='col-4 fw-semibold text-muted'>Статус</label>

                <div className='col-8 d-flex align-items-center'>
                  <span
                    className={`badge ${
                      !data?.server?.status ? 'badge-light-danger' : 'badge-light-success'
                    }`}
                  >
                    {!!data?.server?.status ? 'Активный' : 'Не активный'}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-4 fw-semibold text-muted'>ID севера</label>

                <div className='col-8 d-flex align-items-center'>
                  <span className='fw-bold fs-6 text-gray-800 me-2'>#{data?.server?.id}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-4 fw-semibold text-muted'>IP сервера</label>

                <div className='col-8 d-flex align-items-center'>
                  <span className='fw-bold fs-6 text-gray-800 me-2'>{data?.server?.ip}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-4 fw-semibold text-muted'>Дата создания</label>

                <div className='col-8 d-flex align-items-center'>
                  <span className='fw-bold fs-6 text-gray-800 me-2'>
                    {moment(data?.server?.created_at * 1000).format('DD.MM.YYYY')}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-4 fw-semibold text-muted'>Действителен до</label>

                <div className='col-8 d-flex align-items-center'>
                  <span className='fw-bold fs-6 text-gray-800 me-2'>
                    {moment(data?.server?.valid).format('DD.MM.YYYY')}
                  </span>
                </div>
              </div>

              <div className='row mb-7 d-flex align-items-center'>
                <label className='col-4 fw-semibold text-muted'>JWT токен</label>

                <div
                  className='col-7 d-flex align-items-center border border-end-0 border-2 rounded-1'
                  style={{padding: 'calc(0.775rem + 1px) calc(1.5rem + 1px)'}}
                >
                  <span
                    className='fw-bold fs-6 text-gray-800 me-2 cursor-pointer'
                    style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
                    id=''
                  >
                    {data?.server?.jwt_token}
                  </span>
                </div>
                <div className='col-1 d-flex flex-end p-0'>
                  <Button
                    className=''
                    style={{borderRadius: '0px 6px 6px 0px'}}
                    color='btn-dark'
                    action={() => copyText(data?.server?.jwt_token)}
                  >
                    <KTIcon iconName='copy' className='fs-2 p-0 w-100 cursor-pointer' />
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
});

const ButtonUpdate = ({getDomains, data}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);

  return (
    <>
      <span
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        onClick={() => {
          setShowCreateAppModal(true);
          setSave(false);
        }}
      >
        <i className='ki-duotone ki-pencil fs-2'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>
      </span>

      <CreateAppModal
        customWidth='mw-550px'
        show={showCreateAppModal}
        handleClose={() => {
          if (save) {
            setShowCreateAppModal(false);
          } else {
            setWarning(true);
          }
        }}
        title={'Редактирование домена'}
      >
        <AddDomain
          setWarning={setWarning}
          warning={warning}
          setSave={setSave}
          save={save}
          show={setShowCreateAppModal}
          getDomains={getDomains}
          isUpdate={true}
          data={data}
        />
      </CreateAppModal>
    </>
  );
};

export default DomainWrapper;
