/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../../store/store';
import PageWrapper from '../../../../modules/PageWrapper';
import {formatNumberToCash} from '../../../../functions/formatNumber';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import moment from 'moment';
import PaginationBe from '../../../../shared/PaginationBe';

const AccountsWrapper = () => {
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Facebook Аккаунты', path: 'tracker/facebook/accounts', isActive: true},
      ]}
      title={'Facebook Аккаунты'}
      firstTimeConfig='today'
      limitDays={7}
    >
      <Accounts />
    </PageWrapper>
  );
};

const Accounts = observer(({isVerify, setPreloader, timeConfig}) => {
  const store = useStore();
  const [accounts, setAccounts] = useState([]);
  const [portionAccounts, setPortionAccounts] = useState([]);
  const [limit, setLimit] = useState(8);
  const [offset, setOffset] = useState(1);

  useEffect(() => {
    const newAccounts = [];
    for (let i = limit * offset - limit; i < limit * offset; i++) {
      if (accounts.length > i) {
        newAccounts.push(accounts[i]);
      }
    }
    setPortionAccounts(newAccounts);
  }, [offset, accounts]);

  useEffect(() => {
    isVerify && timeConfig.time_config && getData();
  }, [isVerify, timeConfig]);

  useEffect(() => {
    const containersXL = document.querySelectorAll('.container-xxl');
    containersXL.forEach((el) => (el.style.maxWidth = '100%'));
    return () => {
      containersXL.forEach((el) => (el.style.maxWidth = ''));
    };
  }, []);

  const getData = async () => {
    const [getAccounts] = await Promise.all([store.trafficsFacebookAccountGetAccounts(timeConfig)]);

    setAccounts(getAccounts);
    setPreloader(false);
  };

  const update = async (id) => {
    await store.trafficsFacebookAccountGetAccountStats({timeConfig, id});
  };

  return (
    <div className='card'>
      <div className='card-body py-4'>
        <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
          <div className='table-responsive'>
            <table className='table align-middle table-row-dashed fs-6 gy-5'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th className='min-w-125px text-start' style={{whiteSpace: 'nowrap'}}>
                    Аккаунт
                  </th>
                  <th className='min-w-125px text-start' style={{whiteSpace: 'nowrap'}}>
                    Байер
                  </th>
                  <th className='min-w-125px text-center' style={{whiteSpace: 'nowrap'}}>
                    Расходы
                  </th>
                  <th className='min-w-125px text-center' style={{whiteSpace: 'nowrap'}}>
                    Токен
                  </th>
                  <th className='min-w-125px text-center' style={{whiteSpace: 'nowrap'}}>
                    Дата отключения
                  </th>

                  <th className='w-90px text-start'></th>
                </tr>
              </thead>

              <tbody className='text-gray-600 fw-semibold'>
                {portionAccounts?.map((item, i) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <div
                          className='d-flex fs-6 text-gray-800'
                          style={{display: 'flex', flexDirection: 'column'}}
                        >
                          <span
                            className='me-2'
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              maxWidth: '350px',
                            }}
                          >
                            {item?.account_name}
                          </span>
                          <span className='me-2 text-gray-400'>#{item?.account_id}</span>
                        </div>
                      </td>
                      <td>
                        <div
                          className='d-flex fs-6 text-gray-800'
                          style={{display: 'flex', flexDirection: 'column'}}
                        >
                          <span className='me-2' style={{whiteSpace: 'nowrap'}}>
                            {item?.bayer_fname} {item?.bayer_lname}
                          </span>
                          <span className='me-2 text-gray-400'>#{item?.bayer_id}</span>
                        </div>
                      </td>
                      <td className='text-center'>{formatNumberToCash(item?.expenses)}</td>
                      <td className='text-center'>
                        <div
                          className={`badge ${
                            !item.active ? 'badge-light-danger' : 'badge-light-success'
                          }`}
                        >
                          {!item.active ? 'Не активный' : 'Активный'}
                        </div>
                      </td>
                      <td className='text-center'>
                        {!!item?.disdate ? (
                          <div className={`badge badge-danger`}>
                            {moment(Date.parse(item?.disdate)).format('DD/MM/YYYY')}
                          </div>
                        ) : (
                          '-'
                        )}
                      </td>

                      <td className='text-end'>
                        {store.roles.includes(38) ? (
                          <></>
                        ) : (
                          <OverlayTrigger
                            delay={{hide: 450, show: 300}}
                            placement='top'
                            overlay={(props) => (
                              <Tooltip className='fs-8' {...props}>
                                Обновление расходов
                              </Tooltip>
                            )}
                          >
                            <span
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              onClick={() => update(item?.id)}
                            >
                              <i className='ki-duotone ki-abstract-33 fs-2'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                              </i>
                            </span>
                          </OverlayTrigger>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        {accounts.length > limit && (
          <PaginationBe
            arrLength={accounts.length}
            limit={limit}
            page={offset}
            setPage={setOffset}
            action={(currentPage) => {
              // console.log(currentPage)
              // onPaginationChange(currentPage);
            }}
          />
        )}
      </div>
    </div>
  );
});

export default AccountsWrapper;
