/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useRef} from 'react';
import css from '../../../../styles/FacebookStatistics.module.scss';
import classNames from 'classnames';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import {useThemeMode} from '../../../../_metronic/partials';
import Navigation from '../../../modules/NavigationBloggers';
import Filter from '../../../table/Filter';
import FilterOfFilters from '../../../table/FilterOfFilters';
import {TrekerButton} from '../influence/bloggers/source/Treker/TrekerButton';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import SearchableParams from '../../../table/SearchableParams';

const groupsObj = [
  {
    label: 'Популярные',
    options: [
      {value: 'advertiser', label: 'Рекламодатель'},
      {value: 'offer', label: 'Оффер'},
      {value: 'app', label: 'Приложения'},
      {value: 'bayer', label: 'Байер'},
    ],
  },
  {
    label: 'Рекламодатель',
    options: [
      {value: 'advertiser', label: 'Рекламодатель'},
      {value: 'offer', label: 'Оффер'},
      {value: 'landing', label: 'Лендинг'},
    ],
  },
  {
    label: 'Фейсбук',
    options: [
      {value: 'app', label: 'Приложения'},
      {value: 'bayer', label: 'Байер'},
      {value: 'traffic', label: 'Кампания'},
      {value: 'pixel', label: 'Пиксель'},
      {value: 'source', label: 'Поток'},
    ],
  },
  {
    label: 'Дата',
    options: [
      {value: 'day', label: 'День'},
      {value: 'month', label: 'Месяц'},
      {value: 'year', label: 'Год'},
    ],
  },
  {
    label: 'Страна',
    options: [{value: 'country', label: 'Страна'}],
  },
];

const Filters = observer(
  ({
    setThemeMode,
    actions,
    isVerify,
    getTable,
    search,
    resultsCount,
    inputSearch,
    linkStore,
    filtersGroup,
    perPage,
  }) => {
    const store = useStore();
    const theme = useThemeMode();
    const navigate = useNavigate();

    const [mode, setMode] = useState('');

    const [group, setGroup] = useState('');
    const [page, setPage] = useState('');
    const [filters, setFilters] = useState({});

    const [filtersGroupOptions, setFiltersGroupOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const [searchableParams, setSearchableParams] = useState([]);

    const [groupOptions, setGroupOptions] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);

    const [firstRender, setFirstRender] = useState(false);

    const [timeInfo, setTimeInfo] = useState('');

    useEffect(() => {
      if (!firstRender && !!search && !!isVerify) {
        setFirstRender(true);
        getFilters();
      }
    }, [isVerify, search]);

    const uniqueOptions = (obj) => {
      const res = Array.from(
        obj
          .flatMap((group) => group.options)
          .reduce((map, option) => map.set(option.value, option), new Map())
          .values()
      );
      return res;
    };

    const getFilters = async () => {
      const [
        getAdvertisers,
        getOffers,
        getLandings,
        getPwa,
        getBayers,
        getCompanies,
        getPixels,
        getBrands,
        getCountries,
      ] = await Promise.all([
        store.getAdvertisersList(),
        store.offersGetOffersList(),
        store.offersGetLandingsList(),
        store.resourcePWAgetApps({
          time_config: 'current_month',
          time_start: '',
          time_end: '',
        }),
        store.trafficsFacebookGetBayersList(),
        store.trafficsFacebookGetCompaniesList(),
        store.getPixels(),
        store.getBrands(),
        store.getCountries(),
      ]);

      const filtersObj = {
        advertiser_id: getAdvertisers,
        offer_id: getOffers,
        landing_id: getLandings,
        app_id: getPwa,
        traffic_id: getCompanies,
        pixel_id: getPixels,
        conversion_id: [],
        platform_id: [],
        event_id: [],
        lead_id: [],
        conversion_type: [
          {id: 'reg', name: 'Регистрация'},
          {id: 'fd', name: 'Первый Депозит'},
          {id: 'rd', name: 'Повторный депозит'},
          {id: 'cpa', name: 'Квалификация'},
        ],
        brand_id: getBrands,
        traffic_type: [
          {name: 'pwa', id: 'pwa'},
          {name: 'telegram', id: 'telegram'},
        ],
        app_type: [
          {name: 'PWA', id: 'pwa'},
          {name: 'IOS', id: 'ios'},
          {name: 'Android', id: 'android'},
        ],
        country_id: getCountries.map((item) => {
          return {name: item.name, id: item.id, flag: item.flag};
        }),
      };

      if (store.roles.includes(6) || store.roles.includes(7)) {
        filtersObj.bayer_id = [];
      } else {
        filtersObj.bayer_id = getBayers;
      }

      setFilters(filtersObj);
      parseUrlWithFilters(search, filtersGroup, groupsObj, filtersObj);
    };

    const updateQueryString = (str, key, value) => {
      const regex = new RegExp(`[?&]${key}=([^&]*)`);
      const match = str.match(regex);

      if (match) {
        if (match[1] === value) {
          return str;
        }
        return str.replace(regex, `${match[0][0]}${key}=${value}`);
      }
      return `${str}${str.includes('?') ? '&' : '?'}${key}=${value}`;
    };

    const createUrlWithoutReq = (options, groups = []) => {
      setSearchableParams(options);

      const params = new URLSearchParams(search);

      params.set('group', groups.map((item) => item.value).join(','));

      options?.forEach(({value, options: opts}) => {
        params.set(value, !!opts?.length ? opts.map((item) => item.value).join(',') : []);
      });

      Array.from(params.keys()).forEach((key) => {
        const existsInOptions = options.some(({value}) => value === key);
        if (!existsInOptions && key !== 'group') {
          params.delete(key);
        }
      });

      const url = decodeURIComponent(params.toString());
      const parsedData = {};

      const pairs = url.split('&');

      pairs.forEach((pair) => {
        const [key, value] = pair.split('=');

        parsedData[key] = value || '';
      });

      let newString = localStorage.getItem(linkStore);
      for (const property in parsedData) {
        newString = updateQueryString(newString, property, parsedData[property]);
      }

      localStorage.setItem(linkStore, newString);
      firstRender && navigate(newString);
    };

    const addSelectedOption = (e) => {
      const newSelectedOptions = [...selectedOptions, e];
      setSelectedOptions(newSelectedOptions);
      createUrlWithoutReq(newSelectedOptions, selectedGroup);
      const obj = filtersGroupOptions.map((item) => {
        const newItem = {...item};
        const newOptions = item.options.filter((op) => op.value !== e.value);
        newItem.options = newOptions;
        return newItem;
      });
      setFiltersGroupOptions(obj);
    };

    const delFilter = (item, index) => {
      const newSelectedOptions = [...selectedOptions];
      newSelectedOptions.splice(index, 1);
      setSelectedOptions(newSelectedOptions);
      setSearchableParams(newSelectedOptions);
      createUrl(newSelectedOptions, selectedGroup);

      if (newSelectedOptions.length === 0) return setFiltersGroupOptions(filtersGroup);
      const newFiltersGroup = [...filtersGroup].map((it) => {
        const newOptions = it.options.filter(
          (op) => !newSelectedOptions.some((el) => el.value === op.value)
        );
        return {...it, options: newOptions};
      });

      setFiltersGroupOptions(newFiltersGroup);
    };

    const deleteOption = (indexS, indexO) => {
      const newSelectedOptions = JSON.parse(JSON.stringify(selectedOptions));
      newSelectedOptions[indexS].options.splice(indexO, 1);
      setSelectedOptions(newSelectedOptions);
      // setSearchableParams(newSelectedOptions);
    };

    const deleteSavedOption = (indexS, indexO) => {
      const newSelectedOptions = JSON.parse(JSON.stringify(selectedOptions));
      newSelectedOptions[indexS].options.splice(indexO, 1);
      setSelectedOptions(newSelectedOptions);
      setSearchableParams(newSelectedOptions);
      createUrl(newSelectedOptions, selectedGroup);
    };

    const deleteOptions = (indexS) => {
      const newSelectedOptions = JSON.parse(JSON.stringify(selectedOptions));
      newSelectedOptions[indexS].options = [];
      setSelectedOptions(newSelectedOptions);
      setSearchableParams(newSelectedOptions);
      createUrl(newSelectedOptions, selectedGroup);
    };

    const createUrl = (options, groups = []) => {
      setSearchableParams(options);

      const params = new URLSearchParams(search);

      params.set('group', groups.map((item) => item.value).join(','));

      options?.forEach(({value, options: opts}) => {
        params.set(value, !!opts?.length ? opts.map((item) => item.value).join(',') : []);
      });
      params.forEach((value, key) => {
        // console.log(`${key}: ${value}`);
      });

      Array.from(params.keys()).forEach((key) => {
        const existsInOptions = options.some(({value}) => value === key);
        if (!existsInOptions && key !== 'group') {
          params.delete(key);
        }
      });
      params.set('per_page', perPage);
      const url = decodeURIComponent(params.toString());

      getTable(`?${url}`, 'changeFilters');
      getNewTime();
    };

    const parseUrlWithFilters = (string, obj1, obj2, filtersObj) => {
      const params = new URLSearchParams(string);

      const groupParam = params.get('group');
      const groupValues = groupParam ? groupParam.split(',') : [];

      const newObj2 = obj2.map((item) => {
        const newOptions = item?.options?.filter(
          (opt) => !groupValues.some((el) => el === opt.value)
        );
        return {...item, options: newOptions};
      });

      const selected = uniqueOptions(obj2)
        .filter((item) => groupValues.includes(item.value))
        .sort((a, b) => groupValues.indexOf(a.value) - groupValues.indexOf(b.value));

      if (!selected.includes('advertiser')) {
        selected.length === 0
          ? groupValues.push('advertiser')
          : groupValues.splice(1, 0, 'advertiser');
      }
      setGroupOptions(newObj2);
      setSelectedGroup(selected);
      setGroup(groupValues[0] || '');

      //Filters/////////////////////////////////////
      const allParams = {};
      params.forEach((value, key) => {
        if (
          key !== 'group' &&
          key !== 'time_config' &&
          key !== 'time_start' &&
          key !== 'time_end' &&
          key !== 'sort' &&
          key !== 'page' &&
          key !== 'per_page'
        ) {
          const values = value.split(',').map((v) => v.trim());

          if (!allParams[key]) {
            allParams[key] = [];
          }
          allParams[key] = allParams[key].concat(values);
        }
      });

      const allParamsEntries = Object.entries(allParams);
      const newSelectedOptions = uniqueOptions(obj1)
        .filter((item) => allParamsEntries.some((el) => el[0] === item.value))
        .map((item) => {
          const find = allParamsEntries.find((el) => el[0] === item.value);
          const options = filtersObj[item.value]
            .filter((opt) => find[1].some((el) => String(el) === String(opt.id)))
            .map((el) => {
              const obj = {
                value: el.id,
                label: el?.name
                  ? typeof el.id === 'number'
                    ? `#${el?.id} ${el?.name}`
                    : el?.name
                  : `#${el?.id} ${el?.first_name} ${el?.last_name}`,
              };

              if (el.flag) {
                obj.flag = el.flag;
              }

              return obj;
            });
          return {...item, options};
        });

      const newObj1 = obj1.map((item) => {
        const newOptions = item?.options?.filter(
          (opt) => !allParamsEntries.map((el) => el[0]).some((el) => el === opt.value)
        );
        return {...item, options: newOptions};
      });

      setFiltersGroupOptions(newObj1);
      setSelectedOptions(newSelectedOptions);
      setSearchableParams(newSelectedOptions);
      getNewTime();
    };

    useEffect(() => {
      if (theme.mode === 'system') {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          setMode('dark');
          setThemeMode('dark');
        } else {
          setMode('light');
          setThemeMode('light');
        }
      } else {
        setMode(theme.mode);
        setThemeMode(theme.mode);
      }
    }, [theme]);

    const formatTimeAgo = (milliseconds) => {
      if (new Date().getTime() - milliseconds * 1000 < 3600000) {
        return moment(milliseconds * 1000)
          .startOf('minute')
          .fromNow();
      }

      return moment(milliseconds * 1000)
        .startOf('hour')
        .fromNow();
    };

    const time = useRef(null);
    const intervalId = useRef(null);

    const getNewTime = () => {
      const newTime = new Date().getTime();
      time.current = newTime;

      if (intervalId.current) {
        clearInterval(intervalId.current);
      }

      intervalId.current = setInterval(() => {
        setTimeInfo(formatTimeAgo(time.current / 1000));
      }, 1000);
    };

    useEffect(() => {
      return () => {
        if (intervalId.current) {
          clearInterval(intervalId.current);
        }
      };
    }, []);

    return (
      <div
        className={css.filtersContainer}
        style={{
          '--bgColor': mode === 'dark' ? '#1B1C22' : '#F9F9F9',
          '--borderColor': mode === 'dark' ? 'hsl(0, 0%, 20%)' : 'hsl(0, 0%, 80%)',
          '--closeColor': mode === 'dark' ? 'hsl(0, 0%, 80%)' : 'hsl(0, 0%, 20%)',
        }}
      >
        <section
          className={css.filtersListContainer}
          style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}
        >
          <div className={css.actions} style={{flexDirection: 'row', flexWrap: 'nowrap'}}>
            <span style={{display: 'block', whiteSpace: 'nowrap'}}>Обновлено: {timeInfo}</span>
            <TrekerButton
              onClick={() => {
                createUrl(selectedOptions, selectedGroup);
              }}
              style={{gap: '0px'}}
              svg={
                <svg
                  viewBox='0 0 30.50039 30.5'
                  xmlns='http://www.w3.org/2000/svg'
                  width='15'
                  height='16'
                >
                  <g data-name='Layer 2' id='Layer_2'>
                    <g id='Interface-Solid'>
                      <g id='interface-solid-multimedia-refresh-button-1'>
                        <path d='M3.2312,10.5H9.25a1.25,1.25,0,0,0,0-2.5H5.36743A13.20678,13.20678,0,0,1,16.415,2.5a12.75654,12.75654,0,0,1,11.68945,7.65039,1.25027,1.25027,0,0,0,2.291-1.00195A15.25661,15.25661,0,0,0,16.415,0,15.47292,15.47292,0,0,0,2.5,7.93555V1.25a1.25,1.25,0,0,0-2.5,0v8A1.25085,1.25085,0,0,0,1.25,10.5Z' />
                        <path d='M29.25,20h-8a1.25,1.25,0,0,0,0,2.5h3.94965A11.00927,11.00927,0,0,1,15.25,28,12.75654,12.75654,0,0,1,3.56055,20.34961a1.25027,1.25027,0,0,0-2.291,1.002A15.25661,15.25661,0,0,0,15.25,30.5,13.625,13.625,0,0,0,28,22.58423V29.25a1.25,1.25,0,0,0,2.5,0v-8A1.25022,1.25022,0,0,0,29.25,20Z' />
                      </g>
                    </g>
                  </g>
                </svg>
              }
            ></TrekerButton>
          </div>
        </section>
        <section className={css.filters} style={{justifyContent: 'space-between'}}>
          <div style={{display: 'flex', gap: '16px', flexWrap: 'wrap'}}>
            {selectedOptions.map((item, index) => {
              return (
                <div className={css.selectContainer} key={item.value}>
                  <Filter
                    filters={filters}
                    item={item}
                    index={index}
                    mode={mode}
                    createUrl={createUrl}
                    setSelectedOptions={setSelectedOptions}
                    selectedOptions={selectedOptions}
                    selectedGroup={selectedGroup}
                    deleteOption={deleteOption}
                    isSearch={!!item.isSearch}
                  />
                  <div className={css.selectClose} onClick={() => delFilter(item, index)}></div>
                </div>
              );
            })}
            <div className={css.filtersSelector}>
              <FilterOfFilters
                tabIndex={1}
                filtersGroupOptions={filtersGroupOptions}
                addSelectedOption={addSelectedOption}
                placeholder={'Добавить фильтр'}
                classButton={'addFilter'}
                mode={mode}
              />
            </div>
          </div>
          <div style={{}}>{actions}</div>
        </section>

        <SearchableParams
          params={searchableParams}
          deleteSavedOption={deleteSavedOption}
          deleteOptions={deleteOptions}
        />

        <section
          className={css.filtersListContainer}
          style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}
        >
          {/* <div className={css.groups}>
            {inputSearch}
            {selectedGroup.length > 0 && (
              <div className={css.list}>
                {selectedGroup.map((el, i) => {
                  if (el.value === 'advertiser') return null;

                  return (
                    <React.Fragment key={el.label}>
                      <div className={css.element}>
                        <span className={css.elementName}>{el.label}</span>
                        <span className={css.elementRemove} onClick={() => deleteGroup(i)}></span>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            )}
            <div className={css.filtersSelector}>
              <FilterOfFilters
                tabIndex={2}
                placeholder={'Добавить группу'}
                filtersGroupOptions={groupOptions}
                addSelectedOption={addSelectedGroup}
                classButton={'addGroup'}
                mode={mode}
              />
            </div>
          </div> */}
          <div className={css.actions}>
            {/* <div>
              <span className='text-gray-600'>{resultsCount} результатов</span>
            </div> */}
            {/* <div>
              <TrekerButton
                onClick={() => {
                  const newSelectedGroup = [
                    {
                      value: 'advertiser',
                      label: 'Рекламодатель',
                    },
                    {
                      value: 'offer',
                      label: 'Оффер',
                    },
                    {
                      value: 'landing',
                      label: 'Лендинг',
                    },
                  ];

                  setSelectedGroup(newSelectedGroup);
                  setGroup(newSelectedGroup.length > 0 ? newSelectedGroup[0].value : '');
                  const newGroup = [...groupsObj].map((it) => {
                    const newOptions = it.options.filter(
                      (op) => !newSelectedGroup.some((el) => el.value === op.value)
                    );
                    return {...it, options: newOptions};
                  });
                  setGroupOptions(newGroup);

                  setFiltersGroupOptions(filtersGroup);
                  const newSelectedOptions = [
                    {value: 'advertiser_id', label: 'Рекламодатель'},
                    {value: 'offer_id', label: 'Оффер'},
                    {value: 'state', label: 'Состояние'},
                    {value: 'type', label: 'Источник'},
                  ];
                  setSelectedOptions(newSelectedOptions);
                  setSearchableParams(newSelectedOptions);
                  createUrl(newSelectedOptions, newSelectedGroup);
                }}
                svg={
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='15'
                    height='16'
                    fill='none'
                    viewBox='0 0 15 16'
                  >
                    <g fill='#292D32'>
                      <path d='M14.667 3a1 1 0 0 0-1-1h-2.518a2.996 2.996 0 0 0-2.816-2h-2a2.996 2.996 0 0 0-2.816 2H1a1 1 0 0 0 0 2h.333v8.333A3.667 3.667 0 0 0 5 16h4.667a3.667 3.667 0 0 0 3.666-3.667V4h.334a1 1 0 0 0 1-1Zm-3.334 9.333c0 .92-.746 1.667-1.666 1.667H5c-.92 0-1.667-.746-1.667-1.667V4h8v8.333Z' />
                      <path d='M5.667 12a1 1 0 0 0 1-1V7a1 1 0 0 0-2 0v4a1 1 0 0 0 1 1ZM9 12a1 1 0 0 0 1-1V7a1 1 0 0 0-2 0v4a1 1 0 0 0 1 1Z' />
                    </g>
                  </svg>
                }
              >
                Сброс
              </TrekerButton>
            </div> */}
          </div>
        </section>
      </div>
    );
  }
);

export {Filters};
