/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useStore} from '../../../../../../store/store';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import InputRange from '../../../../../shared/InputRange';
import {SelectorWithSearch} from '../../../../../shared/Selectors';
import Preloader from '../../../../../modules/Preloader';
import PaginationBe from '../../../../../shared/PaginationBe';
import {formatNumberToCash} from '../../../../../functions/formatNumber';

const Leads = ({timeConfig, id, isVerify}) => {
  const store = useStore();

  const [preloader, setPreloader] = useState(false);
  const [page, setPage] = useState(1);
  const [prePage, setPrePage] = useState(10);
  const [offerId, setOfferId] = useState('');
  const [amount, setAmount] = useState('');
  const [sort, setSort] = useState('last_dep_created.desc');

  const [priceMinMax, setPriceMinMax] = useState({});
  const [offers, setOffers] = useState([]);
  const [leads, setLeads] = useState({});

  useEffect(() => {
    isVerify && !!timeConfig.time_config && getInfoForPageLeads();
  }, [timeConfig, isVerify]);

  const getInfoForPageLeads = async () => {
    const [getOffers] = await Promise.all([store.offersGetOffersList(), getData(1)]);

    setOffers([
      {value: '', label: 'Все офферы'},
      ...getOffers.map((item) => {
        return {value: item?.id, label: item.name};
      }),
    ]);
  };

  const getData = async (currentPage) => {
    setPreloader(true);
    setPage(currentPage);
    const leads = await store.salesManagerRdGetLeads({
      timeConfig,
      id,
      page: currentPage,
      prePage,
      offer_id: offerId,
      amount,
      sort,
    });
    setLeads(leads);
    setPreloader(false);
  };

  const filterOffer = async (offer_id) => {
    setPreloader(true);
    setPage(1);
    setOfferId(offer_id);
    const leads = await store.salesManagerRdGetLeads({
      timeConfig,
      id,
      page: 1,
      prePage,
      offer_id,
      amount,
      sort,
    });
    setLeads(leads);
    setPreloader(false);
  };

  const filterRange = async (range) => {
    setPreloader(true);
    setPage(1);
    setAmount(`${range[0]}-${range[1]}`);
    const leads = await store.salesManagerRdGetLeads({
      timeConfig,
      id,
      page: 1,
      prePage,
      offer_id: offerId,
      amount: `${range[0]}-${range[1]}`,
      sort,
    });
    setLeads(leads);
    setPreloader(false);
  };

  const filterSort = async (filter) => {
    setPreloader(true);
    setPage(1);
    setSort(filter);
    const leads = await store.salesManagerRdGetLeads({
      timeConfig,
      id,
      page: 1,
      prePage,
      offer_id: offerId,
      amount,
      sort: filter,
    });
    setLeads(leads);
    setPreloader(false);
  };

  return (
    <>
      <div className='col-xl-12 mb-5 mb-xl-10' style={{position: 'relative'}}>
        <div className='card mb-5 mb-xl-10'>
          <div className='d-flex justify-content-end card-header pt-7 border-0'>
            <div className='w-100 d-flex flex-wrap justify-content-between ' style={{gap: '20px'}}>
              <div className='d-flex flex-wrap' style={{gap: '20px'}}>
                <SelectorWithSearch
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      height: '42px',
                    }),
                  }}
                  placeholder='Оффер...'
                  options={offers}
                  actions={(e) => filterOffer(e.value)}
                  value={offers.find((item) => item.value === offerId)}
                />
              </div>
              <InputRange
                style={{transform: 'translateY(6px)'}}
                valueMin={priceMinMax?.min}
                valueMax={priceMinMax?.max}
                action={(e) => filterRange(e)}
              />
            </div>
          </div>
          <div className='card-body p-9'>
            <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
              <div className='table-responsive'>
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-3'>
                  <thead>
                    <tr className='text-start text-gray-500 fw-bold fs-7 text-uppercase'>
                      <td className='text-end min-w-150px'>
                        <span
                          className='d-flex justify-content-start align-items-center cursor-pointer'
                          onClick={() => {
                            if (sort === 'id.asc') {
                              filterSort('id.desc');
                            } else {
                              filterSort('id.asc');
                            }
                          }}
                        >
                          ID
                          {sort !== 'id.asc' ? (
                            <i
                              className={`ki-duotone ki-down fs-1 ${
                                sort === 'id.desc' && 'text-primary'
                              }`}
                            />
                          ) : (
                            <i
                              className={`ki-duotone ki-up fs-1 ${
                                sort === 'id.asc' && 'text-warning'
                              }`}
                            />
                          )}
                        </span>
                      </td>
                      <td className='text-end min-w-200px'>
                        <span
                          className='d-flex justify-content-center align-items-center cursor-pointer'
                          onClick={() => {
                            if (sort === 'count_deposit.asc') {
                              filterSort('count_deposit.desc');
                            } else {
                              filterSort('count_deposit.asc');
                            }
                          }}
                        >
                          КОЛ-ВО ДЕПОЗИТОВ
                          {sort !== 'count_deposit.asc' ? (
                            <i
                              className={`ki-duotone ki-down fs-1 ${
                                sort === 'count_deposit.desc' && 'text-primary'
                              }`}
                            />
                          ) : (
                            <i
                              className={`ki-duotone ki-up fs-1 ${
                                sort === 'count_deposit.asc' && 'text-warning'
                              }`}
                            />
                          )}
                        </span>
                      </td>
                      <td className='text-end min-w-200px'>
                        <span
                          className='d-flex justify-content-center align-items-center cursor-pointer'
                          onClick={() => {
                            if (sort === 'amount.asc') {
                              filterSort('amount.desc');
                            } else {
                              filterSort('amount.asc');
                            }
                          }}
                        >
                          СУММА ДЕПОЗИТОВ
                          {sort !== 'amount.asc' ? (
                            <i
                              className={`ki-duotone ki-down fs-1 ${
                                sort === 'amount.desc' && 'text-primary'
                              }`}
                            />
                          ) : (
                            <i
                              className={`ki-duotone ki-up fs-1 ${
                                sort === 'amount.asc' && 'text-warning'
                              }`}
                            />
                          )}
                        </span>
                      </td>
                      <td className='text-end min-w-200px'>
                        <span
                          className='d-flex justify-content-center align-items-center cursor-pointer'
                          onClick={() => {
                            if (sort === 'last_dep_created.asc') {
                              filterSort('last_dep_created.desc');
                            } else {
                              filterSort('last_dep_created.asc');
                            }
                          }}
                        >
                          КРАЙНИЙ ДЕПОЗИТ
                          {sort !== 'last_dep_created.asc' ? (
                            <i
                              className={`ki-duotone ki-down fs-1 ${
                                sort === 'last_dep_created.desc' && 'text-primary'
                              }`}
                            />
                          ) : (
                            <i
                              className={`ki-duotone ki-up fs-1 ${
                                sort === 'last_dep_created.asc' && 'text-warning'
                              }`}
                            />
                          )}
                        </span>
                      </td>
                      <td className='text-end'></td>
                    </tr>
                  </thead>

                  <tbody className='fw-bold text-gray-600'>
                    {leads?.rows?.map((item, index) => {
                      return (
                        <tr key={`${item.id}-${index}`}>
                          <td className='text-start'>#{item.id}</td>

                          <td className='text-center'>{item?.count_deposit}</td>

                          <td className='text-center'>
                            <span className='badge badge-light-success me-auto'>
                              {formatNumberToCash(item?.amount)}
                            </span>
                          </td>

                          <td className='text-center'>
                            {moment(item?.last_dep_created * 1000).format('ll')}
                          </td>

                          <td className='text-end'>
                            <Link to={`/traffic/leads/${item?.id}`}>
                              <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                <i className='ki-duotone ki-switch fs-2'>
                                  <span className='path1'></span>
                                  <span className='path2'></span>
                                </i>
                              </span>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {preloader && <Preloader style={{border: '0'}} />}
              </div>
            </div>
            <PaginationBe
              arrLength={leads?.total}
              limit={prePage}
              page={page}
              setPage={setPage}
              action={(currentPage) => getData(currentPage)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(Leads);
